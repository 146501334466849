import {
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconButton,
  IconName,
  type MinimalSubscriptionResponse,
  PnlLookbackEnum,
  type Position,
  useBlotterTable,
  useObservable,
  type UsePersistedBlotterTable,
  usePersistedBlotterTable,
} from '@talos/kyoko';
import { asset, type AssetColumnProps } from '@talos/kyoko/src/components/BlotterTable/columns/columns';
import { PerformanceActionType } from 'containers/Portfolio/Performance/PerformanceReducer';
import { usePerformanceContext } from 'containers/Portfolio/Performance/providers/PerformanceStateAndTabsProvider';
import { useEffect } from 'react';
import { combineLatest, map, scan, shareReplay, startWith } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import { PortfolioManagementBlotterDataItem } from '../../types/PortfolioManagementBlotterDataItem';
import type { ContextSelectionBlotterParams } from '../../types/types';
import { BlotterIndent } from '../BlotterIndent';
import { usePortfolioManagementBlotterPositionRequestContext } from '../PortfolioManagementPositionRequestProvider';
import { portfolioManagementPositionRequestSlice } from '../portfolioManagementPositionRequestSlice';
import { usePMSSubAccountPositionsSub } from '../PortfolioPerformanceBlotter/usePMSSubAccountPositionsSub';
import { useRollupTreeGridBuilders } from '../PortfolioRiskBlotter/useRollupTreeGridBuilders';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { getPositionMapKey } from '../types';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import { usePortfolioBreakdownColumns } from './usePortfolioBreakdownColumns';

export const assetComponent = asset({
  id: 'Asset',
  field: 'Asset',
  type: 'asset',
  pinned: 'left',
  sortable: true,
  width: 150,
  title: 'Asset',
  params: {
    assetTypeField: 'gridData.AssetType',
    colorful: true,
  } satisfies AssetColumnProps<PortfolioManagementBlotterDataItem>,
});

const { setColumnsForTags } = portfolioManagementPositionRequestSlice.actions;
export function PortfolioBreakdownBlotter({ blotterID, wrapperContext, subAccountId }: ContextSelectionBlotterParams) {
  const { dispatch } = usePerformanceContext();
  useEffect(() => {
    dispatch({
      type: PerformanceActionType.PnlLookbacksChange,
      payload: {
        pnlLookbacks: [PnlLookbackEnum.H24],
      },
    });
  }, [dispatch]);

  const { riskSubAccountObs } = usePortfolioManagementProvider();

  const perfObs = usePMSSubAccountPositionsSub();

  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);
  const gridObs = useObservable<MinimalSubscriptionResponse<PortfolioManagementBlotterDataItem>>(() => {
    return combineLatest([
      sharedObs,
      perfObs.pipe(
        // this specific startWith immediately starts the observable so that combineLatest doesn't have to wait
        startWith({ data: [] }),
        scan((result, next) => {
          next.data.forEach(item => {
            result[getPositionMapKey(item)] = item;
          });
          return result;
        }, {} as Record<string, Position>)
      ),
    ]).pipe(
      map(data => {
        const [risk, positions] = data;
        // combine risk and position data
        const outputData = risk.data.map(item => {
          const key = getPositionMapKey(item.gridData);
          const position = positions[key];
          return new PortfolioManagementBlotterDataItem(item, position);
        });
        return {
          ...risk,
          data: outputData,
        };
      })
    );
  }, [perfObs, sharedObs]);

  const { defaultColumns } = usePortfolioBreakdownColumns();
  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioManagementBlotterDataItem> = usePersistedBlotterTable(
    blotterID,
    {
      columns: defaultColumns,
      sort: '+ag-Grid-AutoColumn',
    }
  );

  const { blotterParams } = useRollupTreeGridBuilders();

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const { dispatch: requestDispatch } = usePortfolioManagementBlotterPositionRequestContext();
  useEffect(() => {
    requestDispatch(setColumnsForTags(persistedBlotterTable.columns));
  }, [persistedBlotterTable.columns, requestDispatch]);

  const groupedDataExportContext = useGroupedDataExportContext('gridData.Asset');
  const blotterTable = useBlotterTable<PortfolioManagementBlotterDataItem>({
    dataObservable: gridObs,
    rowID: 'rowID',
    selection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
    context: groupedDataExportContext,
    getContextMenuItems,
    groupDefaultExpanded: -1,
    supportColumnColDefGroups: true,
    ...blotterParams,
    columns: persistedBlotterTable.columns,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    sort: persistedBlotterTable.initialSort,
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  return (
    <>
      <BlotterTableFilters
        {...blotterTable.blotterTableFiltersProps}
        showFilterBuilder={false}
        showQuickFilterOnly={false}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
          </>
        }
        suffix={
          <RiskBlotterSuffixMenu
            riskObs={sharedObs}
            exportDataAsCSV={blotterTable.exportDataAsCSV}
            exportDataAsExcel={blotterTable.exportDataAsExcel}
            label={wrapperContext.label}
          />
        }
      />
      <BlotterIndent h="100%">
        <BlotterTable {...blotterTable} />
      </BlotterIndent>
      {dialogs}
    </>
  );
}
