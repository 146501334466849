import { isLookbackWindow, type DateRangeOrLookback, type Leaves, type Paths } from '@talos/kyoko';
import type { SerializedDateRangeOrLookback } from '@talos/kyoko/src/components/DateRangePicker/dateRange';
import { array, boolean, mixed, number, object, string, type InferType } from 'yup';
import type { OperationsOverviewShowBy, OpsOverviewFilter } from '../../OperationsOverview/types';
import type { RollupTreePositionData } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { BaseBlotterProps } from '../components/types';
import type { PortfolioRiskDataItem } from './PortfolioRiskDataItem';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';

export type PortfolioRiskBlotterGridData = PortfolioRiskGridData & RollupTreePositionData;

/** Group key prefixes for Underlying level data */
export const UNDERLYING_GROUP_PREFIX = 'Underlying::';
export const ASSET_GROUP_PREFIX = 'Asset::';
export const BOOK_GROUP_PREFIX = 'Book::';
export const ROLLUP_GROUP_PREFIX = 'Rollup::';

/** Tenor Map from Server format to Display Format */
export const TENOR_COLUMN_MAP = {
  Outright: { header: 'Outright', tooltip: 'for positions in instruments without an expiry' },
  W1: { header: '1W', tooltip: 'at 1W Tenor' },
  M1: { header: '1M', tooltip: 'at 1M Tenor' },
  M3: { header: '3M', tooltip: 'at 3M Tenor' },
  M6: { header: '6M', tooltip: 'at 6M Tenor' },
  M9: { header: '9M', tooltip: 'at 9M Tenor' },
  Y1: { header: '1Y', tooltip: 'at 1Y Tenor' },
} as const;
export type TenorServerTypes = keyof typeof TENOR_COLUMN_MAP;
/** Tenor display values in the order they should show */
export const TENOR_DISPLAY_ORDER: TenorServerTypes[] = Object.keys(TENOR_COLUMN_MAP) as TenorServerTypes[];

/** Moneyness Map from Server format to Display Format */
export const MONEYNESS_COLUMN_MAP = {
  Outright: { header: 'Outright', tooltip: 'for positions in instruments without a strike' },
  D10: { header: '10D', tooltip: 'at 10D strike' },
  D25: { header: '25D', tooltip: 'at 25D strike' },
  D50: { header: '50D', tooltip: 'at 50D strike' },
  D75: { header: '75D', tooltip: 'at 75D strike' },
  D90: { header: '90D', tooltip: 'at 90D strike' },
} as const;
export type MoneynessServerTypes = keyof typeof MONEYNESS_COLUMN_MAP;
/** Moneyness display values in the order they should show */
export const MONEYNESS_DISPLAY_ORDER: MoneynessServerTypes[] = Object.keys(
  MONEYNESS_COLUMN_MAP
) as MoneynessServerTypes[];

export type Greeks = keyof typeof GREEK_DISPLAY_NAME_MAP;

export const GREEK_DISPLAY_NAME_MAP = {
  Delta: 'Delta Sensitivity',
  Gamma: 'Gamma Sensitivity',
  Vega: 'Vega Sensitivity',
  Vanna: 'Vanna Sensitivity',
  Volga: 'Volga Sensitivity',
  Rho: 'Rho Sensitivity',
  Theta: 'Theta Sensitivity',
} as const;

export type GreekOrderItem = {
  [P in Greeks]: {
    tooltipBase: (homeCurrency: string) => string;
  };
};

/** All Greeks to be pivoted on, based on greek name (as shown by server) and
 * the tooltip that should display on screen
 */
export const GREEK_ORDER = {
  Delta: {
    tooltipBase: (homeCurrency: string) => `change in position value (${homeCurrency}) for a 1% change in spot price`,
  },
  Gamma: {
    tooltipBase: (homeCurrency: string) =>
      `change in position value (${homeCurrency}) due to change in Delta for a 1% change in spot price`,
  },
  Vega: {
    tooltipBase: (homeCurrency: string) => `change in position value (${homeCurrency}) for a 1% change in volatility`,
  },
  Vanna: {
    tooltipBase: (homeCurrency: string) =>
      `change in position value (${homeCurrency}) for a joint move of 1% change in volatility and spot price`,
  },
  Volga: {
    tooltipBase: (homeCurrency: string) =>
      `change in position value (${homeCurrency}) due to change in Vega for a 1% change in volatility`,
  },
  Rho: {
    tooltipBase: (homeCurrency: string) =>
      `change in position value (${homeCurrency}) for a 1 basis point change in the projection rate`,
  },
  Theta: {
    tooltipBase: (homeCurrency: string) =>
      `change in position value (${homeCurrency}) for a 1 day decrease in the time to expiry`,
  },
} as const satisfies GreekOrderItem;
export type PortfolioRiskResultType = 'Asset' | 'AssetMissingMark';

/** Non-pivot column builders */
export const NON_PIVOT_DISPLAY_COLUMNS = {
  'gridData.Equivalent.CcyDeltaExposure': {
    field: (aggMode: RiskAggMode) =>
      `gridData.Equivalent.CcyDeltaExposure.${aggMode}` satisfies Leaves<PortfolioRiskDataItem>,
    title: (aggMode: RiskAggMode, homeCurrency: string) => `${aggMode} Delta Exp (${homeCurrency})`,
    description: (aggMode: RiskAggMode, homeCurrency: string) =>
      `${aggMode} Delta Exposure: ${aggMode} equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
  },
} as const satisfies {
  [P in Paths<PortfolioRiskDataItem>]?: {
    field: (aggMode: RiskAggMode) => Leaves<PortfolioRiskDataItem>;
    title: (aggMode: RiskAggMode, homeCurrency: string) => string;
    description: (aggMode: RiskAggMode, homeCurrency: string) => string;
  };
};

export interface ContextSelectionBlotterParams {
  blotterID: string;
  wrapperContext: BaseBlotterProps;
  subAccountId: number | undefined;
  tag?: string;
}

export const RISK_AGG_MODES = ['Net', 'Gross'] as const;
export const RISK_PIVOT_TYPES = ['Tenor', 'Moneyness'] as const;

export type RiskPivotType = (typeof RISK_PIVOT_TYPES)[number];
export type RiskAggMode = (typeof RISK_AGG_MODES)[number];
export type OverviewChartDisplayType = 'byUnderlying' | 'byInstrument';

export const portfolioViewStateSchema = object({
  selectedPortfolioId: number().optional(),
  selectedMarketAccountIds: array(string().required()).optional(),
  dateRange: mixed<SerializedDateRangeOrLookback>().test('dateRange', 'Invalid date range', value => {
    if (!value) {
      return true;
    }
    if (isLookbackWindow(value)) {
      return true;
    }
    const typeOfFrom = typeof value.from;
    const typeOfTo = typeof value.to;
    if (value.from != null && value.to != null && typeOfFrom !== typeOfTo) {
      return false;
    }
    return true;
  }),
  showRollupHierarchy: boolean().required(),
  showZeroBalances: boolean().required(),
  includeCash: boolean().required(),
  overviewChartDisplayType: mixed<OverviewChartDisplayType>().required(),
  riskPivotType: mixed<RiskPivotType>().required(),
  riskPivotAggMode: mixed<RiskAggMode>().required(),
  opsOverviewShowBy: mixed<OperationsOverviewShowBy>().required(),
  opsOverviewFilter: object()
    .shape({
      Symbols: array(string().optional()).optional(),
    })
    .required(),
});

// build the view state from the expected storage schema
export type SerializedPortfolioViewState = InferType<typeof portfolioViewStateSchema>;
export type PortfolioViewState = Omit<SerializedPortfolioViewState, 'dateRange' | 'opsOverviewFilter'> & {
  dateRange: DateRangeOrLookback;
  opsOverviewFilter: OpsOverviewFilter; // couldnt figure out how to properly type this object with yup, kept complaining, so just doing this
};
