import {
  useDefaultColumns,
  type AgGridPriceProps,
  type Column,
  type ColumnDef,
  type SizeColumnParams,
} from '@talos/kyoko';
import type { AssetColumnProps } from '@talos/kyoko/src/components/BlotterTable/columns/columns';
import type { GreekColumnParams } from '@talos/kyoko/src/components/BlotterTable/columns/greeks';
import { useFeatureFlag, useRollupTreeRef } from 'hooks';
import { compact } from 'lodash';
import { useDisplaySettings } from 'providers/DisplaySettingsProvider';
import { useMemo } from 'react';
import type { PortfolioManagementBlotterDataItem } from '../../types/PortfolioManagementBlotterDataItem';
import { usePnlTagColumns } from '../usePnlTagColumns';
import { getPnLColumn } from '../utils';

export const usePortfolioPerformanceColumns = (): {
  defaultColumns: Column[];
} => {
  const { homeCurrency } = useDisplaySettings();
  const { mapping: baseMapping } = usePortfolioPerformanceColumnDefs(homeCurrency);

  const baseVisibleColumns = useBaseVisibleColumns();
  const { extraColMapping: pnlTagMapping, visiblePnlTagColumns } = usePnlTagColumns('performance', homeCurrency);

  const fullMapping = useMemo(() => {
    const fullMappingInput = {
      ...baseMapping,
      ...pnlTagMapping,
    };
    return new Map<string, Column>(
      Object.entries(fullMappingInput).filter(([, value]) => value !== undefined) as [string, Column][]
    );
  }, [pnlTagMapping, baseMapping]);

  const fullVisibleColumns = useMemo(() => {
    return [...baseVisibleColumns, ...visiblePnlTagColumns].filter(item => !!fullMapping.get(item));
  }, [baseVisibleColumns, fullMapping, visiblePnlTagColumns]);

  const defaultColumns = useDefaultColumns(fullVisibleColumns, fullMapping);
  return {
    defaultColumns,
  };
};

export function usePortfolioPerformanceColumnDefs(homeCurrency: string) {
  const rollupTreeRef = useRollupTreeRef();
  const { showSubAccountPositionPnLTagColumns } = useFeatureFlag();
  return useMemo(() => {
    const mapping = {
      pmsWarnings: {
        id: 'warnings',
        field: 'gridData.PMSWarningColumnValue',
        type: 'pmsWarnings',
        title: { intlKey: 'warning' },
        aggregate: true,
        pinned: 'left',
      },
      subAccount: {
        id: 'SubAccount',
        field: 'gridData.SubAccount',
        title: 'Sub Account',
        type: 'subAccountName',
        sortable: true,
        params: {
          rollupTree: rollupTreeRef,
        },
      },
      assetField: {
        id: 'Asset',
        field: 'gridData.Asset',
        type: 'asset',
        pinned: 'left',
        sortable: true,
        width: 150,
        title: 'Asset',
        params: {
          assetTypeField: 'gridData.AssetType',
          colorful: true,
        } satisfies AssetColumnProps<PortfolioManagementBlotterDataItem>,
      },
      weight: {
        id: 'Weight',
        field: 'gridData.Equivalent.Weight',
        title: 'Weight (%)',
        width: 80,
        type: 'percent',
        sortable: true,
        description: 'Weight of the position in the portfolio.',
      },
      position: {
        id: 'Position',
        field: 'gridData.Position',
        title: 'Position Qty',
        type: 'size',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'gridData.Asset',
          trimTrailingZeroes: true,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        description: 'Position normalized in asset currency.',
      },
      positionHome: {
        type: 'size',
        field: 'gridData.Equivalent.Position',
        title: `Position (${homeCurrency})`,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
          highlightNegative: true,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        description: 'Position normalized in home currency, by using mark and underlying prices.',
      },
      price: {
        field: 'gridData.MarkPrice',
        title: 'Mark Price',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.MarkPriceCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Most recent mark price for the instrument.',
      },
      priceHome: {
        type: 'size',
        field: 'position.Equivalent.MarkPrice',
        hide: true,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        title: { intlKey: 'markPriceHomeCurrency' },
        titleIntlKeyValues: { homeCurrency },
        aggregate: true,
        description: "Most recent mark price for the instrument, converted to the user's home currency.",
      },
      deltaExpNet: {
        field: 'gridData.Equivalent.CcyDeltaExposure.Net',
        type: 'size',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        title: `Net Delta Exp (${homeCurrency})`,
        width: 150,
        description: `Net Delta Exposure: Net equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
      },
      deltaExpGross: {
        field: 'gridData.Equivalent.CcyDeltaExposure.Gross',
        type: 'size',
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        sortable: true,
        aggregate: true,
        hide: true,
        title: `Gross Delta Exp (${homeCurrency})`,
        width: 150,
        description: `Gross Delta Exposure: Gross equivalent delta notional in home currency (${homeCurrency}) from holding this position (the economic exposure driving performance)`,
      },
      avgCost: {
        title: 'Avg Cost',
        field: 'position.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'position.AvgCostCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: 'Average cost for the instrument provided by the venue.',
      },
      avgCostHome: {
        title: `Avg Cost (${homeCurrency})`,
        field: 'position.Equivalent.AvgCost',
        type: 'price',
        width: 150,
        params: {
          quoteCurrencyField: 'gridData.Equivalent.Currency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: "Average cost for the instrument, converted to the user's home currency.",
      },
      fees: {
        field: 'position.CumFees',
        type: 'size',
        title: 'Fees',
        params: {
          currencyField: 'position.PnLCurrency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        aggregate: true,
        description: `Cumulative trading fees paid (or rebates received) (in settlement currency for derivatives, home currency otherwise)`,
      },
      feesHome: {
        field: `position.Equivalent.CumFees`,
        type: 'size',
        title: `Fees (${homeCurrency})`,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        aggregate: true,
        description: `Cumulative trading fees paid (in home currency).`,
      },
      fundingPnL: getPnLColumn({
        title: 'Funding PnL',
        field: 'position.FundingPnL',
        currencyField: 'position.FundingPnLCurrency',
        currencyType: 'quote',
        homeCurrency,
        description: 'Cumulative funding payment paid or received in settlement currency.',
      }),
      fundingPnlHome: getPnLColumn({
        title: 'Funding PnL',
        field: 'position.Equivalent.FundingPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Cumulative funding payment paid or received in home currency.',
      }),
      realizedPnL: getPnLColumn({
        title: 'Realized PnL',
        field: 'position.RealizedPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Cumulative realized P&L.',
      }),
      realizedPnLHome: getPnLColumn({
        title: 'Realized PnL',
        field: 'position.Equivalent.RealizedPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Cumulative realized P&L in home currency.',
      }),
      unrealizedPnL: getPnLColumn({
        title: 'Unrealized PnL',
        field: 'position.UnrealizedPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Cumulative unrealized P&L.',
      }),
      unrealizedPnLHome: getPnLColumn({
        title: 'Unrealized PnL',
        field: 'position.UnrealizedPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Cumulative unrealized P&L in home currency.',
      }),
      totalPnL: getPnLColumn({
        title: 'Total LTD PnL',
        field: 'position.TotalPnL',
        currencyType: 'quote',
        homeCurrency,
        description: 'Sum of Unrealized, Realized and Funding P&Ls, net of Fees.',
      }),
      totalPnLHome: getPnLColumn({
        title: 'Total LTD PnL',
        field: 'position.TotalPnL',
        currencyType: 'home',
        homeCurrency,
        description: 'Sum of Unrealized, Realized and Funding P&Ls, net of Fees. In home currency.',
      }),
      pnl24H: getPnLColumn({
        title: '24H PnL',
        field: 'position.PnLLookbacks.H24.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 24 hours to the current price mark.',
      }),
      pnl7D: getPnLColumn({
        title: '7D PnL',
        field: 'position.PnLLookbacks.D7.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 7 days to the current price mark.',
      }),
      pnl30D: getPnLColumn({
        title: '30D PnL',
        field: 'position.PnLLookbacks.D30.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 30 days to the current price mark.',
      }),
      pnl365D: getPnLColumn({
        title: '365D PnL',
        field: 'position.PnLLookbacks.D365.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the 365 days to the current price mark.',
      }),
      pnlIntraday: getPnLColumn({
        title: 'Intraday PnL',
        field: 'position.PnLLookbacks.Today.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: "P&L on asset from start of day to the current price mark (e.g. today's gain/loss).",
      }),
      pnlWTD: getPnLColumn({
        title: 'WTD PnL',
        field: 'position.PnLLookbacks.WeekToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the week to date to the current price mark.',
      }),
      pnlMTD: getPnLColumn({
        title: 'MTD PnL',
        field: 'position.PnLLookbacks.MonthToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the month to date to the current price mark.',
      }),
      pnlYTD: getPnLColumn({
        title: 'YTD PnL',
        field: 'position.PnLLookbacks.YearToDate.Equivalent.PnLDelta',
        currencyType: 'home',
        homeCurrency,
        description: 'P&L on assets over the year to date to the current price mark.',
      }),
      underlyingPrice: {
        field: 'position.UnderlyingPrice',
        title: 'Underlying Price',
        type: 'price',
        hide: true,
        width: 130,
        params: {
          quoteCurrencyField: 'position.UnderlyingQuoteCurrency',
        } satisfies AgGridPriceProps<PortfolioManagementBlotterDataItem>,
        description: "Most recent fair price for the instrument's underlying provided by the venue.",
      },
      positionDelta: {
        field: 'position.Delta',
        title: 'Delta',
        type: 'delta',
        hide: true,
        aggregate: true,
        params: {
          securityField: 'gridData.Asset',
        } satisfies GreekColumnParams<PortfolioManagementBlotterDataItem>,
        description:
          "Delta measures the rate of change of an instrument's price with respect to changes in its underlying asset's price. ",
      },
      positionEquivalentDelta: {
        field: 'position.Equivalent.Delta',
        type: 'size',
        title: `Delta (${homeCurrency})`,
        hide: true,
        params: {
          currencyField: 'gridData.Equivalent.Currency',
          highlightNegative: true,
        } satisfies SizeColumnParams<PortfolioManagementBlotterDataItem>,
        description: `Delta (${homeCurrency}) measures the rate of change of an instrument's price with respect to changes in its underlying asset's price, in home currency terms. `,
      },
    } as const satisfies Record<string, ColumnDef<PortfolioManagementBlotterDataItem> | undefined>;

    if (showSubAccountPositionPnLTagColumns) {
      const excludedColumns = [
        'underlyingPrice',
        'fees',
        'feesHome',
        'unrealizedPnL',
        'unrealizedPnLHome',
        'realizedPnL',
        'realizedPnLHome',
        'totalPnL',
        'totalPnLHome',
        'fundingPnL',
        'fundingPnlHome',
        'pnl24H',
        'pnl7D',
        'pnl30D',
        'pnl365D',
        'pnlIntraday',
        'pnlWTD',
        'pnlMTD',
        'pnlYTD',
      ] satisfies Array<keyof typeof mapping>;
      const mappingWithoutExcludedColumns = {
        ...mapping,
        ...Object.fromEntries(excludedColumns.map(column => [column, undefined])),
      };

      return {
        mapping: mappingWithoutExcludedColumns,
      };
    }

    return {
      mapping,
    };
  }, [homeCurrency, rollupTreeRef, showSubAccountPositionPnLTagColumns]);
}

function useBaseVisibleColumns() {
  const { showSubAccountPositionPnLTagColumns } = useFeatureFlag();
  const visibleColumns: Array<keyof ReturnType<typeof usePortfolioPerformanceColumnDefs>['mapping']> = useMemo(
    () =>
      compact([
        'pmsWarnings',
        'weight',
        'position',
        'positionHome',
        'price',
        'deltaExpNet',
        'realizedPnLHome',
        'unrealizedPnLHome',
        'totalPnLHome',
        showSubAccountPositionPnLTagColumns ? 'avgCost' : 'avgCostHome',
        'feesHome',
        'pnl24H',
        'pnl7D',
        'pnl30D',
        'pnl365D',
        'pnlIntraday',
        'pnlWTD',
        'pnlMTD',
        'pnlYTD',
      ]),
    [showSubAccountPositionPnLTagColumns]
  );
  return visibleColumns;
}
