import { Position, type PositionEquivalent, PositionUpdateSourceEnum, ProductTypeEnum } from '@talos/kyoko';
import type { Balance } from '../../../../types';

/**
 * The UnifiedPosition is a temporary frontend concept which lets us combine Balances and Positions into one entity.
 *
 * There are getters defined to help blotters and other consumers to use the underlying data correctly.
 *
 * To create a UnifiedPosition, you can pass either a Balance or Position along with some metadata.
 */
export class UnifiedPosition extends Position {
  underlying: string;

  // spotEquity is grabbed from Balance.Equity, and regarded here as spotEquity. There is an equity getter on this class further down
  spotEquity?: string;
  initialMargin?: string;
  maintenanceMargin?: string;

  market?: string;
  marketAccountGroup?: string;

  override Equivalent?: UnifiedPositionEquivalent;

  get markPriceUnified(): string | undefined {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.MarkPrice ?? this.Equivalent?.ConversionRate;
    }

    return this.MarkPrice;
  }

  get markPriceCurrencyUnified(): string | undefined {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.MarkPriceCurrency ?? this.Equivalent?.Currency;
    }

    return this.MarkPriceCurrency;
  }

  get markPriceEquivalentUnified(): string | undefined {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.Equivalent?.MarkPrice ?? this.Equivalent?.ConversionRate;
    }

    return this.Equivalent?.MarkPrice;
  }

  get equity() {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.spotEquity;
    } else {
      return this.UnrealizedPnL;
    }
  }

  get equityCurrency() {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.Asset;
    } else {
      return this.PnLCurrency;
    }
  }

  get equityEquivalent() {
    if (this.AssetType === ProductTypeEnum.Spot) {
      return this.Equivalent?.spotEquity;
    } else {
      return this.Equivalent?.UnrealizedPnL;
    }
  }

  get balance() {
    return this.AssetType === ProductTypeEnum.Spot ? this.Amount : undefined;
  }

  get balanceEquivalent() {
    return this.AssetType === ProductTypeEnum.Spot ? this.Equivalent?.Amount : undefined;
  }

  /** Creates a clone of an already existing UnifiedPosition instance */
  static clone(up: UnifiedPosition): UnifiedPosition {
    return new UnifiedPosition({
      position: up,
      metadata: {
        underlying: up.underlying,
        marketAccountName: up.MarketAccount,
        marketName: up.market,
        marketAccountGroup: up.marketAccountGroup,
      },
    });
  }

  constructor({
    metadata,
    ...params
  }: ({ position: Position } | { balance: Balance }) & {
    metadata: { marketAccountName: string; marketName?: string; marketAccountGroup?: string; underlying: string };
  }) {
    if ('position' in params) {
      super(params.position);

      this.Equivalent = params.position.Equivalent;
    } else {
      const balance = params.balance;
      super({
        Amount: balance.Amount,
        AssetType: ProductTypeEnum.Spot,
        Asset: balance.Currency,
        MarketAccount: metadata.marketAccountName,
        LastUpdateTime: balance.LastUpdateTime,
        Status: balance.Status,
        PositionSource: PositionUpdateSourceEnum.Gateway,
        OutstandingBuy: balance.OutstandingBuy,
        OutstandingSell: balance.OutstandingSell,
        Delta: balance.Amount,
      });

      this.spotEquity = balance.Equity;
      this.initialMargin = balance.InitialMargin;
      this.maintenanceMargin = balance.MaintenanceMargin;

      this.Equivalent = balance.Equivalent
        ? {
            Amount: balance.Equivalent.Amount,
            Currency: balance.Equivalent.Currency,
            OutstandingBuy: balance.Equivalent.OutstandingBuy,
            OutstandingSell: balance.Equivalent.OutstandingSell,
            Delta: balance.Equivalent.Amount,
            ConversionRate: balance.Equivalent.ConversionRate,
            initialMargin: balance.Equivalent.InitialMargin,
            maintenanceMargin: balance.Equivalent.MaintenanceMargin,
            spotEquity: balance.Equivalent.Equity,
          }
        : undefined;
    }

    this.market = metadata.marketName;
    this.marketAccountGroup = metadata.marketAccountGroup;
    this.underlying = metadata.underlying;
  }
}

type UnifiedPositionEquivalent = PositionEquivalent & {
  spotEquity?: string;
  initialMargin?: string;
  maintenanceMargin?: string;
};
