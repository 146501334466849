import {
  AGGRID_AUTOCOLUMN_ID,
  Button,
  ButtonVariants,
  type ExportGridMode,
  FormControlSizes,
  type GetValueForGroupNodeContext,
  IconName,
  type Leaves,
  MixpanelEvent,
  type PopoverState,
  type UseBlotterTable,
  createExportFileName,
  useCurrenciesContext,
  useMixpanel,
  useSecuritiesContext,
} from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-community';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import type { BasePortfolioManagementDataItem } from '../types/BasePortfolioManagementDataItem';
import { ASSET_GROUP_PREFIX, BOOK_GROUP_PREFIX, ROLLUP_GROUP_PREFIX, UNDERLYING_GROUP_PREFIX } from '../types/types';

export const RiskBlotterExportMenuItems = ({
  exportDataAsCSV,
  exportDataAsExcel,
  label,
  handleClose,
}: {
  exportDataAsCSV: UseBlotterTable<unknown>['exportDataAsCSV'];
  exportDataAsExcel: UseBlotterTable<unknown>['exportDataAsExcel'];
  label: string;
  handleClose: PopoverState['close'];
}) => {
  const mixpanel = useMixpanel();

  const handleClickExport = useCallback(
    (mode: ExportGridMode) => {
      mixpanel.track(mode === 'CSV' ? MixpanelEvent.ExportRows : MixpanelEvent.ExportRowsToExcel);
      const fileName = createExportFileName({
        name: label,
        mode,
      });
      switch (mode) {
        case 'CSV': {
          mixpanel.track(MixpanelEvent.ExportRows);
          exportDataAsCSV({
            fileName,
            skipRowGroups: false,
          });
          break;
        }
        case 'Excel': {
          mixpanel.track(MixpanelEvent.ExportRowsToExcel);
          exportDataAsExcel({
            fileName,
            sheetName: fileName,
          });
          break;
        }
      }
    },
    [exportDataAsCSV, exportDataAsExcel, label, mixpanel]
  );

  const exportModes: ExportGridMode[] = ['Excel', 'CSV'];
  return (
    <>
      {exportModes.map(mode => (
        <Button
          key={mode}
          startIcon={IconName.DocumentUpload}
          variant={ButtonVariants.Default}
          size={FormControlSizes.Small}
          onClick={() => {
            handleClickExport(mode);
            handleClose();
          }}
        >
          Export {mode}
        </Button>
      ))}
    </>
  );
};

/** Get Blotter AgGrid context object addition to support Excel and CSV Export */
export function useGroupedDataExportContext(
  assetField: Leaves<BasePortfolioManagementDataItem>
): Pick<GetValueForGroupNodeContext, 'getValueForGroupedNode'> {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();

  const getSecurityValue = useCallback(
    (symbol: string) => {
      return currenciesBySymbol.get(symbol)?.Symbol ?? securitiesBySymbol.get(symbol)?.DisplaySymbol ?? symbol;
    },
    [currenciesBySymbol, securitiesBySymbol]
  );

  return useMemo(() => {
    function getGroupedColumnValue(node: IRowNode): string {
      function getRowValue(node: IRowNode): string {
        const { groupData, data } = node;
        const groupValue = groupData?.[AGGRID_AUTOCOLUMN_ID];
        if (typeof groupValue !== 'string') {
          throw new Error('Missing groupData group value: ', {
            cause: node,
          });
        }
        const groupValueArray = groupValue.split('::');
        const groupPrefix = groupData ? groupValueArray[0] + '::' : '';
        let dataValue = '';
        switch (groupPrefix) {
          case ROLLUP_GROUP_PREFIX:
          case BOOK_GROUP_PREFIX:
            dataValue = groupValueArray[1] ?? '';
            break;
          case UNDERLYING_GROUP_PREFIX:
            dataValue = getSecurityValue(groupValueArray[1] ?? '');
            break;
          case ASSET_GROUP_PREFIX:
            dataValue = getSecurityValue(get(data, assetField) ?? '');
            break;
          default: {
            throw new Error('Unexpected group prefix');
          }
        }
        return dataValue;
      }
      const hierarchyArray = [getRowValue(node)];

      // AgGrid has a root parent which is not useful for the hierarchy
      while (node.parent?.level != null && node.parent.level >= 0) {
        node = node.parent;
        hierarchyArray.unshift(getRowValue(node));
      }
      return hierarchyArray.join(' > ');
    }
    const result: Pick<GetValueForGroupNodeContext, 'getValueForGroupedNode'> = {
      getValueForGroupedNode: (node, mode) => {
        return getGroupedColumnValue(node);
      },
    };
    return result;
  }, [getSecurityValue, assetField]);
}
