import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterTable,
  BlotterTableFilters,
  ButtonVariants,
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  FormControlSizes,
  HStack,
  IconButton,
  IconName,
  isColumnGroup,
  ToggleHorizontal,
  useBlotterTable,
  useObservable,
  usePersistedBlotterTable,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { shareReplay } from 'rxjs';
import { usePortfolioManagementProvider } from '../../providers/PortfolioManagementProvider';
import {
  getPortfolioViewActions,
  usePortfolioViewStateSelector,
} from '../../stateManagement/portfolioViewLayoutSlice.hooks';
import type { PortfolioRiskDataItem } from '../../types/PortfolioRiskDataItem';
import type { ContextSelectionBlotterParams, RiskAggMode, RiskPivotType } from '../../types/types';
import { BlotterIndent } from '../BlotterIndent';
import { useGroupedDataExportContext } from '../RiskBlotterExportMenuItems';
import { RiskBlotterSuffixMenu } from '../RiskBlotterSuffixMenu';
import { useRollupTreeMenu } from '../useRollupTreeMenu';
import { usePivotColumnShowHide } from './usePivotColumnShowHide';
import { usePortfolioRiskColumns } from './usePortfolioRiskColumns';
import { useRollupTreeGridBuilders } from './useRollupTreeGridBuilders';

const riskPivotButtonBarOptions: Array<{ value: RiskPivotType; displayName: string }> = [
  {
    value: 'Tenor',
    displayName: 'By Tenor',
  },
  {
    value: 'Moneyness',
    displayName: 'By Moneyness',
  },
];

const riskAggModeButtonBarOptions: Array<{ value: RiskAggMode; displayName: string }> = [
  {
    value: 'Net',
    displayName: 'Net',
  },
  {
    value: 'Gross',
    displayName: 'Gross',
  },
];

const { updateRiskPivotAggMode, updateRiskPivotType } = getPortfolioViewActions();

/**
 * PortfolioRiskBlotter display the underlyings and assets of the selected subAccount (Rollup or Book) in a hierarchical grid.
 */
export function PortfolioRiskBlotter({ blotterID, wrapperContext, subAccountId }: ContextSelectionBlotterParams) {
  const { riskSubAccountObs } = usePortfolioManagementProvider();
  const { riskPivotAggMode, riskPivotType } = usePortfolioViewStateSelector();
  const dispatch = useAppStateDispatch();
  const sharedObs = useObservable(() => riskSubAccountObs.pipe(shareReplay(1)), [riskSubAccountObs]);

  const { defaultColumns, defaultColumnsFlat } = usePortfolioRiskColumns();
  const persistedBlotterTable: UsePersistedBlotterTable<PortfolioRiskDataItem> = usePersistedBlotterTable(blotterID, {
    columns: defaultColumnsFlat,
    sort: `+${AGGRID_AUTOCOLUMN_ID}`,
  });

  const { blotterParams } = useRollupTreeGridBuilders();

  const { getContextMenuItems, dialogs } = useRollupTreeMenu();

  const customColumnUpdate = usePivotColumnShowHide({
    riskPivotType,
    riskAggMode: riskPivotAggMode,
    sort: persistedBlotterTable.initialSort,
  });

  const fullColumns = useMemo(() => {
    const pivotColumnGroups = defaultColumns.filter(isColumnGroup);
    return [...persistedBlotterTable.columns, ...pivotColumnGroups];
  }, [defaultColumns, persistedBlotterTable.columns]);

  const groupedDataContext = useGroupedDataExportContext('gridData.Asset');
  const blotterTable = useBlotterTable<PortfolioRiskDataItem>({
    dataObservable: sharedObs,
    rowID: 'rowID',
    context: groupedDataContext,
    getContextMenuItems,
    selection: DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
    groupDefaultExpanded: -1,
    supportColumnColDefGroups: true,
    customColumnUpdate,
    ...blotterParams,
    columns: persistedBlotterTable.columns,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    sort: persistedBlotterTable.initialSort,
    groupableColumns: fullColumns,
  });

  useEffect(() => {
    if (blotterTable.gridApi) {
      if (subAccountId) {
        blotterTable.gridApi.expandAll();
      } else {
        blotterTable.gridApi.collapseAll();
      }
    }
  }, [subAccountId, blotterTable.gridApi]);

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  const onRiskPivotAggModeChange = useCallback(
    (val: RiskAggMode) => {
      dispatch(updateRiskPivotAggMode(val));
    },
    [dispatch]
  );

  const onRiskPivotTypeChange = useCallback(
    (val: RiskPivotType) => {
      dispatch(updateRiskPivotType(val));
    },
    [dispatch]
  );

  return (
    <>
      <BlotterIndent>
        <BlotterTableFilters
          {...blotterTable.blotterTableFiltersProps}
          showFilterBuilder={false}
          quickFilterPrefix={
            <HStack gap="spacingDefault">
              <ToggleHorizontal<RiskAggMode>
                value={riskPivotAggMode}
                options={riskAggModeButtonBarOptions}
                onChange={onRiskPivotAggModeChange}
                data-testid="risk-agg-mode-toggle"
              />
              <ToggleHorizontal<RiskPivotType>
                value={riskPivotType}
                options={riskPivotButtonBarOptions}
                onChange={onRiskPivotTypeChange}
                data-testid="risk-pivot-type-toggle"
              />
            </HStack>
          }
          prefix={
            <>
              <IconButton
                icon={IconName.ListExpand}
                size={FormControlSizes.Small}
                variant={ButtonVariants.Default}
                onClick={expandAllGroups}
                data-testid="expand-all-button"
              />
              <IconButton
                icon={IconName.ListCollapse}
                size={FormControlSizes.Small}
                variant={ButtonVariants.Default}
                onClick={collapseAllGroups}
              />
            </>
          }
          suffix={
            <RiskBlotterSuffixMenu
              riskObs={sharedObs}
              exportDataAsCSV={blotterTable.exportDataAsCSV}
              exportDataAsExcel={blotterTable.exportDataAsExcel}
              label={wrapperContext.label}
            />
          }
        />
      </BlotterIndent>
      <BlotterIndent h="100%">
        <BlotterTable {...blotterTable} />
      </BlotterIndent>
      {dialogs}
    </>
  );
}
