import { createSelector, createSlice, current, isDraft, type PayloadAction } from '@reduxjs/toolkit';
import {
  AllocationValueTypeEnum,
  BaseField,
  ConnectionStatusEnum,
  DateField,
  DateTimeDurationPickerValueType,
  DecisionStatusEnum,
  EMPTY_ARRAY,
  Field,
  InstrumentCompositionEnum,
  MultiSelectorField,
  MultilegReportingTypeEnum,
  NumericField,
  OrdTypeEnum,
  OrderMarketStatusEnum,
  PricingMode,
  ProductTypeEnum,
  SelectorField,
  SideEnum,
  SyntheticProductTypeEnum,
  UnifiedLiquidityEnum,
  Unit,
  canCcyFutureOrPerp,
  canParseAsDuration,
  getGroup,
  getPositionAmount,
  getScaleFromIncrement,
  intlDefaultFormatter,
  isCFD,
  isDelta1Multileg,
  isFuture,
  isOption,
  isOptionStrategy,
  isPerpetualSwap,
  isSecurityQuantoFuture,
  isSpot,
  mapParameterToField,
  parseDuration,
  shouldDisplayReasonabilityCheckForProduct,
  toBigWithDefault,
  type Allocation,
  type ConnectionType,
  type ExecutionReport,
  type FieldData,
  type Market,
  type MarketAccount,
  type MarketDataStatistics,
  type Order,
  type OrderStrategy,
  type Position,
  type ProductGroup,
  type Security,
  type StringSelectItem,
} from '@talos/kyoko';
import Big from 'big.js';
import type { WritableDraft } from 'immer';
import { freeze } from 'immer';
import type { AppState } from 'providers/AppStateProvider/types';
import { getUnifiedLiquidityLegParams } from 'utils/getUnifiedLiquidityLegParams';
import type { LegParams } from '../../../providers/OMSContext.types';
import type { IOrderPreset } from '../../../providers/OrderPresetsContext';
import { STRATEGY_GROUP } from '../../../tokens/order';
import type { OrderStrategiesEnum } from '../../../tokens/orderStrategy';
import { DISABLED_REASONABILITY_CHECK_STRATEGIES, ParameterKeysEnum } from '../../../tokens/orderStrategy';
import type { Balance } from '../../../types';
import { isMultiLegSecurity, isStrategySupportedBySecurity } from '../../../utils/security';
import { getDDHDefaultsID } from '../../DDH/utils';
import { filterOrderPresets } from '../../OrderPresets/OrderPresetSearchSelect';
import { setGlobalSymbol } from '../Common';
import { initialRefDataState, setOMSSettings } from '../OMSReferenceDataSlice';
import { numberIsPositive } from '../commonFieldRules';
import type { OMSReferenceDataState } from '../types';
import { getAllowedCurrencies, getOrderCurrency } from '../useGetOrderCurrency';
import { getReduceFirstSupport } from '../useGetReduceFirstSupport';
import { getReduceOnlySupport } from '../useGetReduceOnlySupport';
import { getNormalizedOrderQty, getOrdType, getOrderAndRFQMarketAccounts } from '../utils';
import {
  allocationTotalValidation,
  clipIntervalValidation,
  clipSizeValidation,
  endTimeValidation,
  greaterThanCumQtyValidation,
  legParamInitiating,
  legParamMarkets,
  notInPastValidation,
  optionPriceRangeValidation,
  priceValidation,
  quantityValidation,
} from './FieldRules';
import { Future, Option, Perp, type MarginCostResponse } from './models';
import type { OrderAdditionalDependencies, OrderFormState, OrderState, PrimeOMSParams } from './types';
import { getHedgingSecurities, initializeStrategyParams } from './utils';
import { mapSyntheticProductTypeToLabel } from './utils/mappers';

export const getInitialState = (): OrderState => ({
  dependencies: {
    tradableSubAccounts: EMPTY_ARRAY,
  },
  referenceData: initialRefDataState,
  form: {
    symbolField: new SelectorField({ idProperty: 'Symbol', name: 'Symbol' }),
    sideField: new Field({ value: SideEnum.Buy }),
    strategyField: new SelectorField({ idProperty: 'Name', name: 'Strategy' }),
    orderPresetField: new SelectorField({
      idProperty: 'id',
      name: 'Preset',
      isRequired: false,
      placeholder: 'Select a preset',
    }),
    quantityField: new NumericField({ name: 'Quantity', scale: undefined }), // quantity validation fully managed by FieldRules.quantityValidation
    orderCurrencyField: new SelectorField(),
    commentsField: new Field({ name: 'Comments', isRequired: false }),
    stagedOrderField: new Field({ name: 'Staged', value: false, isRequired: false }),
    groupField: new Field({ name: 'Group', isRequired: false }),
    priceField: new NumericField({ name: 'Price' }).validate(),
    priceModeField: new SelectorField<PricingMode>({ name: 'Price Ccy', value: PricingMode.Default }),
    parameters: {},
    allocationValueTypeField: new Field({ value: AllocationValueTypeEnum.Percentage }),
    allocations: EMPTY_ARRAY,
    orderMarketsField: new MultiSelectorField({ name: 'Market Account' }),

    multilegTypeField: new SelectorField({ isRequired: false }),
    legParams: new Field({
      name: 'Leg Params',
      isRequired: false,
    }),
    unifiedLiquidityLegParams: new Field({
      name: 'Unified Leg Params',
      isRequired: false,
    }),
    isDDHEnabled: new SelectorField({ name: 'Dynamic Delta Hedging', value: false }),
    ddhStrategyField: new SelectorField({ idProperty: 'Name', name: 'DDH Strategy', isRequired: false }),
    ddhSymbolField: new SelectorField({ idProperty: 'Symbol', name: 'DDH Symbol', isRequired: false }),
    option: undefined,
    perp: undefined,
    future: undefined,
  },
  initialized: false,
  isLoading: false,
  orderBeingModified: undefined,
  productGroup: 'Spot',
  marginCostRequestID: undefined,
  marginCostResponse: undefined,
  positions: new Map(),
  marketDataStatistics: undefined,
});

export const mapAllocationsToField = (
  allocations: Allocation[],
  valueType = AllocationValueTypeEnum.Percentage,
  isUserEntry = false
) => {
  return allocations.map(alloc => ({
    // Allocation.subAccount sends empty string as empty selection, but we want that to be undefined here
    subAccountField: new Field({
      name: 'Sub Account',
      value: alloc.subAccount ? alloc.subAccount : undefined,
      isTouched: isUserEntry,
    }).validate(),
    valueField: new NumericField({
      name: 'Allocation amount',
      unit: valueType === AllocationValueTypeEnum.Percentage ? Unit.Percent : Unit.Decimal,
    }).updateValue(alloc.value, !isUserEntry),
  }));
};
const updatePriceFieldVisibility = (state: WritableDraft<OrderState>, ordType: OrdTypeEnum) => {
  const isPriceFieldRequired = ordType !== OrdTypeEnum.Market;

  if (!isPriceFieldRequired) {
    state.form.priceField = state.form.priceField.updateValue(undefined);
  }

  state.form.priceField = state.form.priceField.setIsRequired(isPriceFieldRequired).setIsVisible(isPriceFieldRequired);
};

const validateAllocations = (state: WritableDraft<OrderState>) => {
  // If there are no sub accounts configured in the system, then allocations is not applicable
  // However it is a required field if it is configured

  const isSubAccountRequired = state.dependencies.tradableSubAccounts.length > 0;

  state.form.allocations = state.form.allocations.map(pair => ({
    subAccountField: pair.subAccountField.setIsRequired(isSubAccountRequired).validate(),
    valueField: pair.valueField.validate(isSubAccountRequired ? [allocationTotalValidation] : [], state.form),
  }));
};

const validateQuantity = (state: WritableDraft<OrderState>) => {
  state.form.quantityField = state.form.quantityField.validate(
    [numberIsPositive, quantityValidation, greaterThanCumQtyValidation],
    state
  );
};

const validatePrice = (state: WritableDraft<OrderState>) => {
  // Even if pricing mode is IV or UnderlyingQuoteCurrency, price can still not be 0
  state.form.priceField = state.form.priceField.validate([optionPriceRangeValidation, priceValidation], state);
};

const validateLegParams = (state: WritableDraft<OrderState>) => {
  // Each leg requires markets to be selected and one leg should be initiating
  state.form.legParams = state.form.legParams.validate([legParamMarkets, legParamInitiating], state);
};

const validateUnifiedLiquidityLegParams = (state: WritableDraft<OrderState>) => {
  // Each leg requires markets to be selected and one leg should be initiating
  state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.validate([legParamMarkets], state);
};

const initializeFieldsFromOrder = (state: WritableDraft<OrderState>, order: ExecutionReport, isModify = false) => {
  const isStaged = order.DecisionStatus === DecisionStatusEnum.Staged;
  state.form.stagedOrderField = state.form.stagedOrderField.updateValue(isStaged);

  state.form.symbolField = state.form.symbolField.setDisabled(isModify).updateValueFromID(order.Symbol);

  handleSymbolChange(state, order.Symbol);

  state.form.sideField = state.form.sideField
    .setDisabled(isModify)
    .updateValue(order.Side === SideEnum.Buy ? SideEnum.Buy : SideEnum.Sell);
  state.form.strategyField = state.form.strategyField
    .setDisabled(isModify && !isStaged)
    .updateValueFromID(order.Strategy);
  state.form.quantityField = state.form.quantityField.updateValue(
    order.OrderQty && toBigWithDefault(order.OrderQty, 0).toFixed()
  );
  state.form.orderPresetField = state.form.orderPresetField.setDisabled(isModify).updateValue(undefined);

  // When contract rather than sending back empty string, back end sends back undefined - which is not the same
  state.form.orderCurrencyField = state.form.orderCurrencyField.setDisabled(isModify).updateValue(order.Currency || '');
  validateQuantity(state);

  if (order.PricingMode) {
    state.form.priceModeField = state.form.priceModeField.updateValue(order.PricingMode as unknown as PricingMode);
    updateLimitPriceScale(state);
  }
  state.form.priceModeField = state.form.priceModeField.setDisabled(isModify);

  // When an order is placed using IV, Price might be set to something like 0.1 - the backend will resolve the 10% and set
  // the Price to be whatever it means, e.g. 29000. It will however keep the 0.1 in the PricingReference property
  // When we modify some time later, we need to read the 0.1 from PricingReference, since Price would be something else
  state.form.priceField = state.form.priceField.updateValue(
    order.PricingMode ? order.PricingReference : order.Price,
    true
  );
  validatePrice(state);
  state.form.orderMarketsField = state.form.orderMarketsField.updateValue(
    order.Markets.filter(m => m.MarketStatus !== OrderMarketStatusEnum.Disabled).map(m => m.MarketAccount)
  );

  state.form.parameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy: state.form.strategyField.value,
    settings: state.referenceData.settings,
    isModify,
  });

  const ordType = getOrdType(
    state.referenceData.settings.showAllInPrices,
    state.form.symbolField.value,
    state.form.strategyField.value
  );
  updatePriceFieldVisibility(state, ordType);

  Object.keys(state.form.parameters).forEach(key => {
    if (order.Parameters?.[key]) {
      let value = order.Parameters?.[key];
      if (key === ParameterKeysEnum.UnifiedLiquidity) {
        const token = state.referenceData.unifiedLiquidityTokens?.get(order.Symbol);
        value = token != null ? value : UnifiedLiquidityEnum.Disabled;
      }
      state.form.parameters[key] = state.form.parameters[key].updateValue(value, true).setTouched(true);
    }
  });

  // If prev order had a Duration for EndTime. Use it to set the order form EndTime as Duration
  if (
    state.form.strategyField.value &&
    state.form.strategyField.value.Parameters &&
    !order.Parameters?.EndTime &&
    order.Parameters?.Duration &&
    canParseAsDuration(order.Parameters.Duration)
  ) {
    const endTimeParam = state.form.strategyField.value.Parameters.find(
      param => param.Name === ParameterKeysEnum.EndTime
    );
    if (endTimeParam) {
      state.form.parameters[ParameterKeysEnum.EndTime] = (
        mapParameterToField(endTimeParam, 0, 0, intlDefaultFormatter) as DateField
      )
        .updateValue({
          value: parseDuration(order.Parameters.Duration),
          type: DateTimeDurationPickerValueType.Duration,
        })
        .setTouched(true);
    }
  }

  if (isModify && state.form.parameters.StartTime) {
    // Allow modifying start time if WaitingForStartTime || Staged
    const allowEdit = [DecisionStatusEnum.WaitingForStartTime, DecisionStatusEnum.Staged].includes(
      order.DecisionStatus
    );
    state.form.parameters.StartTime = state.form.parameters.StartTime.setDisabled(!allowEdit);
  }

  if (state.form.parameters.StartTime && state.form.parameters.EndTime) {
    state.form.parameters.EndTime = (state.form.parameters.EndTime as DateField).setRelativeDate({
      type: DateTimeDurationPickerValueType.DateTime,
      value: state.form.parameters.StartTime.value,
    });
  }
  if (order.SubAccount) {
    const allocations: Allocation[] = [
      {
        subAccount: order.SubAccount,
        value: '1',
      },
    ];
    state.form.allocations = mapAllocationsToField(allocations, AllocationValueTypeEnum.Percentage);
  }
  if (order.Allocation) {
    const allocations: Allocation[] = order.Allocation.Allocations.map(alloc => ({
      subAccount: alloc.SubAccount,
      value: alloc.Value || '1',
    }));
    state.form.allocationValueTypeField = state.form.allocationValueTypeField.updateValue(order.Allocation.ValueType);
    state.form.allocations = mapAllocationsToField(allocations, order.Allocation.ValueType);
  }
  if (isModify && state.form.parameters.ReduceOnly) {
    state.form.parameters.ReduceOnly = state.form.parameters.ReduceOnly.setDisabled(isModify);
  }

  state.form.commentsField = state.form.commentsField.updateValue(order.Comments);
  state.form.groupField = state.form.groupField.updateValue(order.Group);

  if (isMultiLegSecurity(state.form.symbolField.value)) {
    const security = state.form.symbolField.value;
    const initiating = order.MultilegParams?.LegParams.map(p => !!p.Initiating) || [];

    const legParams: LegParams[] = [
      { Markets: [], Initiating: initiating[0], Symbol: security.MultilegDetails.Legs[0].Symbol, LegIndex: 0 },
      { Markets: [], Initiating: initiating[1], Symbol: security.MultilegDetails.Legs[1].Symbol, LegIndex: 1 },
    ];

    state.form.multilegTypeField = state.form.multilegTypeField.setDisabled(isModify);

    const enableUnifiedLiquididty =
      state.form.parameters.UnifiedLiquidity?.value === UnifiedLiquidityEnum.Enabled &&
      state.referenceData.unifiedLiquidityTokens?.get(security.Symbol) != null;
    const unififiedLiquidityLegMarketAccounts: string[] = [];
    const unifiedLegSummary = order.LegSummary?.find(
      leg => leg.MultilegReportingType === MultilegReportingTypeEnum.Parent && leg.Symbol !== security.Symbol
    );
    order.Markets.filter(m => m.MarketStatus !== OrderMarketStatusEnum.Disabled).forEach(market => {
      if (!market.MarketAccount) {
        return;
      }
      const leg = order.LegSummary?.[market.LegSummaryIndex];
      if (leg && legParams[leg.LegIndex]) {
        legParams[leg.LegIndex].Markets.push(market.MarketAccount);
      } else if (enableUnifiedLiquididty && unifiedLegSummary && unifiedLegSummary.Symbol === market.Symbol) {
        unififiedLiquidityLegMarketAccounts.push(market.MarketAccount);
      }
    });

    if (enableUnifiedLiquididty) {
      const unifiedLiquidityLegParams = getUnifiedLiquidityLegParams(
        state.form.symbolField.value.Symbol,
        state.referenceData.unifiedLiquidityTokens,
        unififiedLiquidityLegMarketAccounts.length ? unififiedLiquidityLegMarketAccounts : undefined
      );
      state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.updateValue(
        unifiedLiquidityLegParams,
        true
      );
      state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.setIsRequired(true);
      validateUnifiedLiquidityLegParams(state);
    }
    state.form.legParams = state.form.legParams.updateValue(legParams, true);
    validateLegParams(state);
  }

  // Dynamic Delta Hedging, DDH
  if (order.Parameters && order.Parameters?.DynamicDeltaHedgeStrategy) {
    state.form.isDDHEnabled = state.form.isDDHEnabled.updateValue(true);
    const ddhSymbol = order.Parameters?.DynamicDeltaHedgeStrategyParams?.['DynamicDeltaHedgeSymbol'];
    const ddhStrategy = order.Parameters.DynamicDeltaHedgeStrategy;
    if (ddhSymbol) {
      state.form.ddhSymbolField = state.form.ddhSymbolField.updateValue(
        state.referenceData.securities.securitiesBySymbol.get(ddhSymbol)
      );
    }
    if (ddhStrategy) {
      state.form.ddhStrategyField = state.form.ddhStrategyField.updateValue(
        state.referenceData.strategies.ddhStrategiesList.find(strategy => strategy.Name === ddhStrategy)
      );
    }
  }
  state.form.isDDHEnabled = state.form.isDDHEnabled.setDisabled(isModify);
  state.form.ddhSymbolField = state.form.ddhSymbolField.setDisabled(isModify);
  state.form.ddhStrategyField = state.form.ddhStrategyField.setDisabled(isModify);
};

const populateDropdownsFromRefData = (state: WritableDraft<OrderState>) => {
  const { securities, strategies, orderPresets } = state.referenceData;

  state.form.symbolField = state.form.symbolField.updateAvailableItems(securities.securitiesList);
  state.form.strategyField = state.form.strategyField.updateAvailableItems(strategies.strategiesList);
  state.form.ddhStrategyField = state.form.ddhStrategyField.updateAvailableItems(strategies.ddhStrategiesList);
  state.form.orderPresetField = state.form.orderPresetField.updateAvailableItems(orderPresets.orderPresetsList, {
    preventDefaultToValue: true,
  });

  const uniqueSyntheticProductTypes = new Set(
    // This should not happen but just in case we have SyntheticProductTypes not covered from the below then we are still
    // able to populate the dropdown correctly
    securities.securitiesList.map(s => s.MultilegDetails?.SyntheticProductType || '').filter(x => !!x)
  );
  // we always want Delta 1 and Synthetic Cross no matter what
  uniqueSyntheticProductTypes.add(SyntheticProductTypeEnum.Delta1Spread);
  uniqueSyntheticProductTypes.add(SyntheticProductTypeEnum.Cross);
  if (state.referenceData.settings.enableOptionTrading) {
    uniqueSyntheticProductTypes.add(SyntheticProductTypeEnum.CallSpread);
    uniqueSyntheticProductTypes.add(SyntheticProductTypeEnum.PutSpread);
    uniqueSyntheticProductTypes.add(SyntheticProductTypeEnum.CallCalendarSpread);
    uniqueSyntheticProductTypes.add(SyntheticProductTypeEnum.PutCalendarSpread);
  }

  const syntheticProductTypeSelectItems: StringSelectItem[] = Array.from(uniqueSyntheticProductTypes.values()).map(
    v => ({
      label: mapSyntheticProductTypeToLabel(v as SyntheticProductTypeEnum),
      value: v,
    })
  );

  state.form.multilegTypeField = state.form.multilegTypeField
    .updateAvailableItems(syntheticProductTypeSelectItems)
    .updateValue(syntheticProductTypeSelectItems[0]);

  // Probably wouldn't happen in Principal but just in case as it has been observed in sales order
  if (securities.securitiesList.length === 1) {
    handleSymbolChange(state, securities.securitiesList[0]?.Symbol);
  }
};

const handleSymbolChange = (state: WritableDraft<OrderState>, symbol?: string) => {
  state.form.orderMarketsField = state.form.orderMarketsField.updateValue(EMPTY_ARRAY, true);
  state.marginCostResponse = undefined;
  state.marketDataStatistics = undefined;

  const security = state.referenceData.securities.securitiesBySymbol.get(symbol || '');
  if (!security) {
    return;
  }

  state.form.ddhSymbolField = state.form.ddhSymbolField.updateAvailableItems(
    getHedgingSecurities(security, state.referenceData.securities.securitiesList)
  );

  state.form.orderPresetField = state.form.orderPresetField.updateAvailableItems(
    filterOrderPresets(state.referenceData.orderPresets.orderPresetsList, {
      security,
      strategies: state.referenceData.strategies.strategiesList,
    })
  );

  state.form.orderPresetField = state.form.orderPresetField.updateValue(undefined, true);

  const group = getGroup(security);
  state.productGroup = group;

  const hasSymbolChanged = state.form.symbolField.value ? state.form.symbolField.value.Symbol !== symbol : false;
  if (hasSymbolChanged) {
    state.form.priceField = getInitialState().form.priceField;
  }

  state.form.symbolField = state.form.symbolField.updateValue(security);

  // Strategy
  const filteredStrategies = state.referenceData.strategies.strategiesList.filter(strat =>
    isStrategySupportedBySecurity(strat, security)
  );
  const selectedStrategy = state.form.strategyField.value;
  // Automatically select a strategy if the one that was selected is not supported for the pair
  const nextStrategy =
    filteredStrategies.find(s => s.Name === selectedStrategy?.Name) ||
    filteredStrategies.find(s => s.Name === state.referenceData.settings.defaultOrderStrategy) ||
    filteredStrategies.find(s => s.Name === state.referenceData.settings.lastStrategyUsed) ||
    filteredStrategies[0];

  state.form.strategyField = state.form.strategyField
    .updateAvailableItems(filteredStrategies)
    .updateValue(nextStrategy);

  updateAvailableCurrencies(state);
  setInitialOrderCcy(state);

  state.form.quantityField = state.form.quantityField.updateValue(
    getNormalizedOrderQty(security, state.form.orderCurrencyField.value),
    true
  );
  validateQuantity(state);

  updateLimitPriceCurrencies(state);

  state.form.parameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy: state.form.strategyField.value,
    settings: state.referenceData.settings,
    isModify: !!state.orderBeingModified,
  });

  const ordType = getOrdType(
    state.referenceData.settings.showAllInPrices,
    state.form.symbolField.value,
    state.form.strategyField.value
  );
  updatePriceFieldVisibility(state, ordType);

  if (isMultiLegSecurity(security)) {
    // The legParams inside the multileg encapsulate each leg's market
    // We don't display the regular market selector for multi legs either
    state.form.orderMarketsField = state.form.orderMarketsField.setIsRequired(false);
    state.form.legParams = state.form.legParams.setIsRequired(true);

    const multilegTypeValue = state.form.multilegTypeField.availableItems.find(
      item => item.value === security.MultilegDetails.SyntheticProductType
    );
    state.form.multilegTypeField = state.form.multilegTypeField.updateValue(multilegTypeValue);

    const initiating = security.MultilegDetails?.Parameters?.LegParams?.map(p => !!p.Initiating) || [];

    const legParams: LegParams[] = [
      { Markets: [], Initiating: initiating[0], Symbol: security.MultilegDetails.Legs[0].Symbol, LegIndex: 0 },
      { Markets: [], Initiating: initiating[1], Symbol: security.MultilegDetails.Legs[1].Symbol, LegIndex: 1 },
    ];

    security.MultilegDetails.Legs.forEach((leg, index) => {
      if (!leg.Markets) {
        return;
      }
      legParams[index].Markets = leg.Markets.map(m => m.MarketAccount);
    });

    state.form.legParams = state.form.legParams.updateValue(legParams, true);
    validateLegParams(state);
  } else {
    state.form.orderMarketsField = state.form.orderMarketsField.setIsRequired(true);
    state.form.legParams = state.form.legParams.setIsRequired(false);
    state.form.legParams = state.form.legParams.updateValue(undefined, true);
    validateLegParams(state);
    state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.updateValue(undefined, true);
    state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.setIsRequired(false);
    validateUnifiedLiquidityLegParams(state);
  }

  if (group === 'Option') {
    state.form.option = Option.createFromSecurity(state.referenceData, security);
  }
  if (group === 'Perp') {
    state.form.perp = Perp.createFromSecurity(state.referenceData, security);
  }
  if (group === 'Future') {
    state.form.future = Future.createFromSecurity(state.referenceData, security);
  }
};

const setInitialOrderCcy = (state: WritableDraft<OrderState>) => {
  if (state.referenceData.settings.enableDerivativeContractDefault !== undefined) {
    // When priming use value in PrimeParams, enableDerivativeContractDefault is undefined on startup
    const initialOrderCurrency = getOrderCurrency(
      state.form.symbolField.value,
      state.referenceData.securities.securitiesBySymbol,
      state.referenceData.strategies.strategiesByName,
      state.referenceData.settings.enableDerivativeContractDefault,
      state.form.sideField.value,
      state.form.strategyField.value?.Name
    );
    state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(initialOrderCurrency, true);
  }
};

const updateAvailableCurrencies = (state: WritableDraft<OrderState>) => {
  const allowedCurrencies = getAllowedCurrencies(
    state.form.symbolField.value,
    state.referenceData.securities.securitiesBySymbol,
    state.referenceData.strategies.strategiesByName,
    state.form.sideField.value as SideEnum,
    state.form.strategyField.value?.Name as OrderStrategiesEnum
  );
  state.form.orderCurrencyField = state.form.orderCurrencyField.updateAvailableItems(allowedCurrencies);
};

const updateLimitPriceCurrencies = (state: WritableDraft<OrderState>) => {
  const security = state.form.symbolField.value;
  const strategy = state.form.strategyField.value;
  const supportedStrategiesForPricingModes = state.referenceData.settings.supportedStrategiesForPricingModes;

  if (!security || !strategy) {
    return;
  }

  const isStrategySupportedForPricingModes = supportedStrategiesForPricingModes.includes(strategy.Name);
  const supportedModes: PricingMode[] = [PricingMode.Default];

  if (security?.SupportedPricingModes?.ImpliedVolatility && isStrategySupportedForPricingModes) {
    supportedModes.push(PricingMode.ImpliedVolatility); // %
  }

  if (security?.SupportedPricingModes?.UnderlyingQuoteCurrency && isStrategySupportedForPricingModes) {
    supportedModes.push(PricingMode.UnderlyingQuoteCurrency); // USD
  }

  const previousValue = state.form.priceModeField.value;
  state.form.priceModeField = state.form.priceModeField.updateAvailableItems(supportedModes);
  const newValue = state.form.priceModeField.value ?? PricingMode.Default;

  if (previousValue !== newValue) {
    state.form.priceField = state.form.priceField.updateValue(undefined, true);
  }

  updateLimitPriceScale(state);
  validatePrice(state);
};

const updateLimitPriceScale = (state: WritableDraft<OrderState>) => {
  const security = state.form.symbolField.value;

  switch (state.form.priceModeField.value) {
    case PricingMode.ImpliedVolatility:
      // https://talostrading.atlassian.net/browse/UI-2809 hard coded 11 decimal place requirement
      state.form.priceField = state.form.priceField.updateScale(11).updateUnit(Unit.Percent);
      break;
    case PricingMode.UnderlyingQuoteCurrency:
      state.form.priceField = state.form.priceField
        .updateScale(getScaleFromIncrement(state.dependencies.USD?.MinIncrement) || 2)
        .updateUnit(Unit.Decimal);
      break;
    case PricingMode.Default:
    default:
      state.form.priceField = state.form.priceField
        .updateScale(getScaleFromIncrement(security?.MinPriceIncrement))
        .updateUnit(Unit.Decimal);
      break;
  }
};

const handleOrderPresetChange = (state: WritableDraft<OrderState>, preset?: IOrderPreset) => {
  if (preset == null) {
    state.form.orderPresetField = state.form.orderPresetField.updateValue(undefined);
    return;
  }
  state.form.orderPresetField = state.form.orderPresetField.updateValue(preset);
  handleStrategyChange(state, preset.strategy, true);
  for (const { key, value } of preset.parameters) {
    handleStrategyParamChange(state, { key, value }, true);
  }

  if (preset.allocations) {
    // If the user has disabled the usage of allocations (>1 sub accounts on an order), then we ignore the presets which have >1 sub acc on them.
    if (preset.allocations.length > 1 && !state.referenceData.settings.useTradeAllocations) {
      state.form.allocations = getInitialState().form.allocations;
    } else {
      const tradableSubAccounts = state.dependencies.tradableSubAccounts.map(subAccount => subAccount.Name);
      const allocations = preset.allocations.filter(allocation => tradableSubAccounts.includes(allocation.subAccount));
      state.form.allocations = mapAllocationsToField(allocations, AllocationValueTypeEnum.Percentage);
      validateAllocations(state);
    }
  }
};

const handleStrategyParamChange = (
  state: WritableDraft<OrderState>,
  { key, value }: { key: string; value?: any },
  isSystemOverride = false
) => {
  const parameterField = state.form.parameters[key];
  if (parameterField) {
    state.form.parameters[key] = parameterField.updateValue(value, isSystemOverride);
    if (key === ParameterKeysEnum.StartTime && state.form.parameters.EndTime) {
      state.form.parameters.EndTime = (state.form.parameters.EndTime as DateField).setRelativeDate(value);
    }

    if (key === ParameterKeysEnum.UnifiedLiquidity) {
      if (value === UnifiedLiquidityEnum.Enabled) {
        if (isMultiLegSecurity(state.form.symbolField.value)) {
          const legParams = getUnifiedLiquidityLegParams(
            state.form.symbolField.value.Symbol,
            state.referenceData.unifiedLiquidityTokens
          );
          state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.updateValue(legParams, true);
          state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.setIsRequired(true);
          validateUnifiedLiquidityLegParams(state);
        }
      } else {
        state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.updateValue(undefined, true);
        state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.setIsRequired(false);
        validateUnifiedLiquidityLegParams(state);
      }
    }
    validateStrategyParams(state);
  }
};

const handleStrategyChange = (state: WritableDraft<OrderState>, strategyName?: string, isSystemOverride = false) => {
  const strategy = strategyName && state.referenceData.strategies.strategiesByName.get(strategyName);
  if (!strategy) {
    return;
  }

  state.form.strategyField = state.form.strategyField.updateValue(strategy, isSystemOverride);

  const newParameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy,
    settings: state.referenceData.settings,
    isModify: !!state.orderBeingModified,
  });

  // When switching strategies we carry over the previous parameter values if it also exists in the new strategy
  Object.keys(newParameters).forEach(paramName => {
    const existingParamField = state.form.parameters[paramName];
    if (existingParamField && existingParamField.isTouched) {
      // Different strategies might have same parameter with same Name but different DisplayName
      // So only safe to carry over the value, not the entire Field object
      if (existingParamField instanceof DateField && existingParamField.dateTimeDuration) {
        // special case with DateTime field duration, since value is an absolute point in time, whereas duration is relative
        // to the point when we actually submit the order. so if there is a duration we want to carry over the duration
        newParameters[paramName] = newParameters[paramName].updateValue(
          existingParamField.dateTimeDuration,
          isSystemOverride
        );
      } else if (newParameters[paramName] instanceof SelectorField) {
        // For enum parameters not only we need to ensure it exists in the new strategy but the previous enum value is also valid
        const enumValueExists = (newParameters[paramName] as SelectorField<number>).availableItems.includes(
          existingParamField.value
        );
        if (enumValueExists) {
          newParameters[paramName] = newParameters[paramName].updateValue(existingParamField.value, isSystemOverride);
        }
      } else {
        newParameters[paramName] = newParameters[paramName].updateValue(existingParamField.value, isSystemOverride);
      }
    }
  });

  state.form.parameters = newParameters;

  const ordType = getOrdType(state.referenceData.settings.showAllInPrices, state.form.symbolField.value, strategy);
  updatePriceFieldVisibility(state, ordType);

  updateAvailableCurrencies(state);

  updateLimitPriceCurrencies(state);
};

export const getAvailableOrderMarketAccounts = (
  security,
  strategy,
  marketAccounts,
  markets,
  availableMarketAccountNamesForAllStrategyGroups: string[]
) => {
  const strategyGroup = strategy?.Group;

  // Bypass changing order markets if multileg as there is only the internal market.
  if (!strategyGroup || security?.Composition === InstrumentCompositionEnum.Synthetic) {
    return availableMarketAccountNamesForAllStrategyGroups;
  }

  return availableMarketAccountNamesForAllStrategyGroups.filter(marketName => {
    //TODO: the below is causing a performance problem opening the order form when we have a lot of market accounts (which happens at retail scale when we have lots of customer users)
    const marketAccount = marketAccounts.marketAccountsList.find(account => account.Name === marketName);
    if (!marketAccount) {
      return false;
    }

    const market = markets.find(market => market.Name === marketAccount.Market);
    if (!market || market.Orders?.Status === ConnectionStatusEnum.Unavailable) {
      return false;
    }

    const disabledForStrategy = strategyGroup !== STRATEGY_GROUP.ALL && strategyGroup !== market.Type;
    return !disabledForStrategy;
  });
};

export const getDefaultOrderMarketAccounts = (
  isMarketOnline,
  security,
  marketAccounts,
  availableMarketAccountNames: string[]
) => {
  const { orderMarketAccounts } = getOrderAndRFQMarketAccounts(
    security?.Markets || EMPTY_ARRAY,
    isMarketOnline,
    marketAccounts.marketAccountsByMarket
  );

  const availableMarketAccountNamesSet = new Set(availableMarketAccountNames);
  return orderMarketAccounts.filter(omA => availableMarketAccountNamesSet.has(omA));
};

/**
 Unlike resetState which is called when we close the order form and reset every state property back to default,
 clean only resets part of the state. e.g. needed for when we go from modifying an order and midway deciding to
 resubmitting another - so we still need to keep certain state (settings/refData) but clean enough to
 transition into another order workflow essentially.
 Since priming does not guarantee full payload, i.e. sometimes payload includes subAccount, strategy and sometimes it does not
 which means we need to keep hold of existing value; in the event the next event is of being modify/resubmit
 then the allocations/subAccount/strategies etc will definitely exist and overwrite it; otherwise we keep current selection
 */
const cleanFormState = (state: WritableDraft<OrderState>) => {
  const prevAllocations = state.form.allocations;
  const prevStrategy = state.form.strategyField;

  state.form = getInitialState().form;

  state.form.allocations = prevAllocations;
  state.form.strategyField = prevStrategy.setDisabled(false);
  state.orderBeingModified = undefined;
  state.marginCostRequestID = undefined;
  state.marginCostResponse = undefined;
  state.productGroup = 'Spot';
};

const validateStrategyParams = (state: WritableDraft<OrderState>) => {
  Object.keys(state.form.parameters).forEach(key => {
    if (state.form.parameters[key].isDisabled) {
      // e.g. When order has started, start time is disabled and we don't want to validate it since it *will* be in the past
      return;
    }
    if (key === 'StartTime' && state.form.parameters.EndTime) {
      state.form.parameters.StartTime = state.form.parameters.StartTime.validate([notInPastValidation], state);
    }
    if (key === 'EndTime' && state.form.parameters.StartTime) {
      state.form.parameters.EndTime = state.form.parameters.EndTime.validate(
        [notInPastValidation, endTimeValidation],
        state
      );
    }

    // https://talostrading.atlassian.net/browse/UI-4182
    if (key === 'ClipSize') {
      state.form.parameters.ClipSize = state.form.parameters.ClipSize.validate(
        [numberIsPositive, clipSizeValidation, quantityValidation],
        state
      );
    }
    if (key === 'ClipInterval') {
      state.form.parameters.ClipInterval = state.form.parameters.ClipInterval.validate([clipIntervalValidation], state);
    }

    if (key === 'ShowQty') {
      state.form.parameters.ShowQty = state.form.parameters.ShowQty.validate(
        [numberIsPositive, quantityValidation],
        state
      );
    }

    if (key === 'MaxImbalanceAmt') {
      state.form.parameters.MaxImbalanceAmt = state.form.parameters.MaxImbalanceAmt.validate(
        [numberIsPositive, quantityValidation],
        {
          ...state,
          form: {
            ...state.form,
            orderCurrencyField: new Field({ value: 'USD' }),
          },
        }
      );
    }
  });
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState: getInitialState(),
  reducers: {
    setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      state.referenceData = action.payload;

      // handle the case where we launched the form so quickly before the app received it's first ref data tick
      if (
        state.initialized &&
        (state.form.symbolField.availableItems.length === 0 ||
          state.form.strategyField.availableItems.length === 0 ||
          // TODO this might be dangerous as these might not exist...
          state.form.ddhStrategyField.availableItems.length === 0)
      ) {
        populateDropdownsFromRefData(state);
      }

      // Selectively chose which dropdowns to update when underlying ref data update while Order form is open
      // We need to handle multileg updating list of available symbols

      // All the state inside a reducer is wrapped by immer proxy, normally you would not need to care since the end result is the same
      // the proxy is purely to track mutations and generating a new copy of state (without mutating the old one) via the changes in the proxy
      // however since we are sharing reference (rather than copying) the reference data living in React's context providers it means that
      // reference checking with === would always be false; we need to get the unwrapped state using current
      const newAvailableDiffers =
        isDraft(state.form.symbolField.availableItems) &&
        action.payload.securities.securitiesList !== current(state.form.symbolField.availableItems);
      if (newAvailableDiffers) {
        state.form.symbolField = state.form.symbolField.updateAvailableItems(action.payload.securities.securitiesList);
      }
    },
    setAdditionalDependencies: (state, action: PayloadAction<OrderAdditionalDependencies>) => {
      state.dependencies = action.payload;

      if (!state.orderBeingModified) {
        if (state.form.allocations.length && action.payload.tradableSubAccounts?.length != null) {
          // Filter out sub-accounts that are no longer tradeable
          state.form.allocations = state.form.allocations.filter(a =>
            a.subAccountField.value
              ? action.payload.tradableSubAccounts!.find(sb => sb.Name === a.subAccountField.value)
              : true
          );
        }

        validateAllocations(state);
      }
    },
    onMount: (state, action: PayloadAction) => {
      if (!state.initialized) {
        populateDropdownsFromRefData(state);
      }
      if (!state.form.allocations.length) {
        state.form.allocations = mapAllocationsToField([
          { subAccount: state.referenceData.defaultSubAccount || '', value: '1' },
        ]);
      }
      state.initialized = true;
    },
    modifyOrder: (state, action: PayloadAction<ExecutionReport | Order>) => {
      cleanFormState(state);
      populateDropdownsFromRefData(state);

      state.orderBeingModified = action.payload;
      initializeFieldsFromOrder(state, action.payload as ExecutionReport, true);
      state.initialized = true;
    },
    resubmitOrder: (state, action: PayloadAction<{ order: ExecutionReport | Order; useRemaining?: boolean }>) => {
      cleanFormState(state);
      populateDropdownsFromRefData(state);

      const order = action.payload.order as ExecutionReport;
      const remaining = Big(order?.OrderQty || '0').minus(order?.CumQty || '0');
      const quantity = action.payload.useRemaining
        ? remaining.gt(0)
          ? remaining.toFixed()
          : undefined
        : order?.OrderQty;

      initializeFieldsFromOrder(state, { ...order, OrderQty: quantity ?? '' }, false);
      validateStrategyParams(state);
      state.initialized = true;
    },
    openNewOrderForm: (state, action: PayloadAction<undefined>) => {
      if (state.form.symbolField.value != null) {
        handleSymbolChange(state, state.form.symbolField.value.Symbol);
      }
    },
    primeNewOrderForm: (state, action: PayloadAction<PrimeOMSParams>) => {
      if (
        state.referenceData.settings.exclusivelyPrimePriceOnRePriming &&
        action.payload.symbol === state.form.symbolField.value?.Symbol
      ) {
        // [UI-4274] - We're only going to update the price and then return early
        if (action.payload.price) {
          state.form.priceField = state.form.priceField.updateValue(action.payload.price);
        }
        if (action.payload.side) {
          state.form.sideField = state.form.sideField.updateValue(action.payload.side);
        }
        return;
      }

      const prevSide = state.form.sideField.value;
      cleanFormState(state);
      populateDropdownsFromRefData(state);

      state.form.priceModeField = state.form.priceModeField.updateValue(
        action.payload.pricingMode ?? PricingMode.Default
      );

      const symbol = action.payload.symbol;
      handleSymbolChange(state, symbol);

      if ('side' in action.payload) {
        state.form.sideField = state.form.sideField.updateValue(action.payload.side);
      } else {
        // keep the previous side.
        state.form.sideField = state.form.sideField.updateValue(prevSide ?? SideEnum.Buy);
      }

      state.form.priceField = state.form.priceField.updateValue(action.payload.price);
      validatePrice(state);
      if (action.payload.marketAccounts) {
        state.form.orderMarketsField = state.form.orderMarketsField.updateValue(action.payload.marketAccounts);
      }

      // Differentiate between priming with empty initial quantity vs using security's NormalSize
      if (action.payload.orderQty != null) {
        state.form.quantityField = state.form.quantityField
          // removes trailing zeroes after the decimal place if we toFixed() a Big object instead of just setting the rawString
          .updateValue(action.payload.orderQty && toBigWithDefault(action.payload.orderQty, 0).toFixed());
      }

      if (action.payload.currency) {
        state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(action.payload.currency);
      }
      validateQuantity(state);

      state.form.groupField = state.form.groupField.updateValue(action.payload.group);

      // Anything below this line might be affected by order presets
      // (E.g. strategy, allocations, parameters, etc.)
      if (action.payload.orderPreset) {
        handleOrderPresetChange(state, action.payload.orderPreset);
        handleUnifiedLiquidity(action, state);
      } else {
        const primePayloadStrategy = state.form.strategyField.availableItems.find(
          s => s.Name === action.payload.selectedStrategy?.Name
        );

        if (primePayloadStrategy) {
          handleStrategyChange(state, primePayloadStrategy.Name);
        }

        handleUnifiedLiquidity(action, state);

        if (action.payload.parameters?.reduceOnly && state.form.parameters.ReduceOnly) {
          state.form.parameters.ReduceOnly = state.form.parameters.ReduceOnly.updateValue(
            action.payload.parameters.reduceOnly
          );
        }
        if (action.payload.parameters?.reduceFirst && state.form.parameters.ReduceFirst) {
          state.form.parameters.ReduceFirst = state.form.parameters.ReduceFirst.updateValue(
            action.payload.parameters.reduceFirst
          );
        }

        if (action.payload.subAccountAllocations) {
          state.form.allocations = mapAllocationsToField(action.payload.subAccountAllocations);
        } else if (action.payload.subAccount) {
          state.form.allocations = mapAllocationsToField([{ subAccount: action.payload.subAccount, value: '1' }]);
        } else {
          const shouldDefaultSubAccount = !state.form.allocations.length && state.referenceData.defaultSubAccount;
          if (shouldDefaultSubAccount) {
            state.form.allocations = mapAllocationsToField([
              { subAccount: state.referenceData.defaultSubAccount!, value: '1' },
            ]);
          }
        }
        validateAllocations(state);
      }
      // End order presets

      state.initialized = true;
    },
    createOrderPreset: (state, action: PayloadAction<IOrderPreset>) => {
      const preset = action.payload;
      state.form.orderPresetField = state.form.orderPresetField.updateAvailableItems(
        filterOrderPresets([...state.referenceData.orderPresets.orderPresetsList, preset], {
          security: state.form.symbolField.value,
          strategies: state.referenceData.strategies.strategiesList,
        })
      );
      handleOrderPresetChange(state, preset);
    },
    updateOrderPreset: (state, action: PayloadAction<{ preset: IOrderPreset; select: boolean }>) => {
      const { preset, select } = action.payload;
      state.form.orderPresetField = state.form.orderPresetField.updateAvailableItems(
        filterOrderPresets(
          state.referenceData.orderPresets.orderPresetsList.map(p => (p.id === preset.id ? preset : p)),
          {
            security: state.form.symbolField.value,
            strategies: state.referenceData.strategies.strategiesList,
          }
        )
      );
      if (select) {
        handleOrderPresetChange(state, preset);
      }
    },
    setSymbol: (state, action: PayloadAction<string | undefined>) => {
      handleSymbolChange(state, action.payload);
    },
    setSide: (state, action: PayloadAction<SideEnum>) => {
      state.form.sideField = state.form.sideField.updateValue(action.payload);
      if (state.form.strategyField.value?.Name === 'EnclaveCross') {
        state.form.quantityField = state.form.quantityField.updateValue(undefined);
      }
      validatePrice(state);
      updateAvailableCurrencies(state);
    },
    setQuantity: (state, action: PayloadAction<string | undefined>) => {
      state.form.quantityField = state.form.quantityField.updateValue(action.payload);
      validateAllocations(state);
      validateQuantity(state);
      validateStrategyParams(state);
    },
    setOrderCurrency: (state, action: PayloadAction<string>) => {
      state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(action.payload);
      state.form.quantityField = state.form.quantityField.updateValue(undefined, true);
    },
    setLimitPrice: (state, action: PayloadAction<string | undefined>) => {
      state.form.priceField = state.form.priceField.updateValue(action.payload);
      validatePrice(state);
    },
    setLimitPriceMode: (state, action: PayloadAction<PricingMode | undefined>) => {
      state.form.priceModeField = state.form.priceModeField.updateValue(action.payload);
      state.form.priceField = state.form.priceField.updateValue(undefined);
      updateLimitPriceScale(state);
      validatePrice(state);
    },
    setStrategy: (state, action: PayloadAction<OrderStrategy>) => {
      state.form.orderPresetField = state.form.orderPresetField.updateValue(undefined);
      handleStrategyChange(state, action.payload?.Name);
    },
    setStrategyParams: (state, { payload }: PayloadAction<{ key: string; value?: any }>) => {
      handleStrategyParamChange(state, payload);
    },
    setOrderPreset: (state, { payload }: PayloadAction<IOrderPreset | undefined>) => {
      handleOrderPresetChange(state, payload);
    },
    setAllocations: (state, action: PayloadAction<Allocation[]>) => {
      state.form.allocations = mapAllocationsToField(action.payload, state.form.allocationValueTypeField.value, true);
      validateAllocations(state);
    },
    setAllocationValueType: (state, action: PayloadAction<AllocationValueTypeEnum>) => {
      const mappedToNewUnit = state.form.allocations.map(pair => ({
        subAccountField: pair.subAccountField,
        valueField: pair.valueField
          .updateUnit(action.payload === AllocationValueTypeEnum.Percentage ? Unit.Percent : Unit.Decimal)
          .updateValue(
            state.form.allocationValueTypeField.value === AllocationValueTypeEnum.Percentage
              ? pair.valueField.displayValue
              : pair.valueField.value
          ),
      }));
      state.form.allocations = mappedToNewUnit;
      state.form.allocationValueTypeField = state.form.allocationValueTypeField.updateValue(action.payload);
      validateAllocations(state);
    },
    setOrderMarkets: (
      state,
      { payload: { markets, isUserEntry = true } }: PayloadAction<{ markets: string[]; isUserEntry?: boolean }>
    ) => {
      state.form.orderMarketsField = state.form.orderMarketsField.updateValue(markets, !isUserEntry);
    },
    setAvailableOrderMarkets: (
      state,
      {
        payload: { availableMarketAccountNames, isMarketOnline },
      }: PayloadAction<{
        availableMarketAccountNames: string[];
        isMarketOnline: (m: Market | string, connectionType: ConnectionType) => boolean;
      }>
    ) => {
      const availableMarketAccountNamesForStrategy = getAvailableOrderMarketAccounts(
        state.form.symbolField.value,
        state.form.strategyField.value,
        state.referenceData.marketAccounts,
        state.referenceData.markets.marketsList,
        availableMarketAccountNames
      );

      const defaultSelectedOrderMarketAccounts = getDefaultOrderMarketAccounts(
        isMarketOnline,
        state.form.symbolField.value,
        state.referenceData.marketAccounts,
        availableMarketAccountNamesForStrategy
      );

      let value = defaultSelectedOrderMarketAccounts;
      if (state.form.orderMarketsField.isTouched) {
        // If field is not touched then just replace with system defaults, if touched then keep user selection as long as user selection still exists
        const existing = state.form.orderMarketsField.value;
        const applicable = existing.filter(e => availableMarketAccountNamesForStrategy.includes(e));

        // if user had manually unselected everything, we want to keep the empty selection
        // we only re-default if their existing non-empty selection is not valid anymore
        if (!existing.length) {
          value = [];
        } else {
          value = applicable.length ? applicable : defaultSelectedOrderMarketAccounts;
        }
      }

      if (!value.length && availableMarketAccountNamesForStrategy.length === 1) {
        value = availableMarketAccountNamesForStrategy;
      }

      state.form.orderMarketsField = state.form.orderMarketsField
        .updateAvailableItems(availableMarketAccountNamesForStrategy)
        .updateValue(value);
    },
    setComments: (state, action: PayloadAction<string | undefined>) => {
      state.form.commentsField = state.form.commentsField.updateValue(action.payload);
    },
    setStagedOrder: (state, action: PayloadAction<boolean>) => {
      state.form.stagedOrderField = state.form.stagedOrderField.updateValue(action.payload);
    },
    setGroup: (state, action: PayloadAction<string | undefined>) => {
      state.form.groupField = state.form.groupField.updateValue(action.payload);
    },
    /** Reset state to initialstate, except for reference data and dependencies. */
    resetState: state => {
      // The referenceData and dependencies are kept since they are read-only which we update on
      // updates, not necessarily whenever the order form changes.
      // Outside the scope of this reducer, there is a difference between useOMSDependencies and useOrderDependencies
      // The former are always mounted, whereas the latter are mounted only when the order form is open and rendered.

      // To remove dependencies or reference data, leverage those actions instead.
      const newState = getInitialState();
      newState.referenceData = state.referenceData;
      newState.dependencies = state.dependencies;
      // During the same session, auto repopulate the last used group when creating a new order
      newState.form.groupField = state.form.groupField.setTouched(false);
      return newState;
    },
    touchAll: state => {
      Object.keys(state.form).forEach(key => {
        if (state.form[key] instanceof BaseField) {
          state.form[key] = state.form[key].setTouched(true);
        }
      });

      Object.keys(state.form.parameters).forEach(param => {
        state.form.parameters[param] = state.form.parameters[param].setTouched(true);
      });

      state.form.allocations = state.form.allocations.map(pair => ({
        subAccountField: pair.subAccountField.setTouched(true),
        valueField: pair.valueField.setTouched(true),
      }));
    },
    filterProductType: (state, action: PayloadAction<ProductGroup | undefined>) => {
      state.productGroup = action.payload;
      if (action.payload === 'Option') {
        state.form.option = Option.createFromBlank(state.referenceData);
      }
      if (action.payload === 'Perp') {
        state.form.perp = Perp.createFromBlank(state.referenceData);
      }
      if (action.payload === 'Future') {
        state.form.future = Future.createFromBlank(state.referenceData);
      }
      state.form.symbolField = state.form.symbolField.updateValue(undefined);
    },
    updateOption: (state, action: PayloadAction<Option>) => {
      state.form.option = action.payload;
      state.marginCostResponse = undefined;

      const symbol = action.payload.security?.Symbol;
      if (symbol) {
        handleSymbolChange(state, symbol);
      } else {
        state.form.symbolField = state.form.symbolField.updateValue(undefined);
      }
    },
    updatePerp: (state, action: PayloadAction<Perp>) => {
      state.form.perp = action.payload;
      state.marginCostResponse = undefined;

      const symbol = action.payload.security?.Symbol;
      if (symbol) {
        handleSymbolChange(state, symbol);
      } else {
        state.form.symbolField = state.form.symbolField.updateValue(undefined);
      }
    },
    updateFuture: (state, action: PayloadAction<Future>) => {
      state.form.future = action.payload;
      state.marginCostResponse = undefined;

      const symbol = action.payload.security?.Symbol;
      if (symbol) {
        handleSymbolChange(state, symbol);
      } else {
        state.form.symbolField = state.form.symbolField.updateValue(undefined);
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setMarginCostRequestID: (state, action: PayloadAction<string | undefined>) => {
      state.marginCostRequestID = action.payload;
    },
    setMarginCost: (state, action: PayloadAction<MarginCostResponse[] | undefined>) => {
      state.marginCostResponse = action.payload;
    },
    setPositions: (state, action: PayloadAction<Map<string, Position>>) => {
      state.positions = freeze(action.payload);
    },
    setMarketDataStatistics: (state, action: PayloadAction<Partial<MarketDataStatistics> | undefined>) => {
      state.marketDataStatistics = freeze(action.payload);
      validatePrice(state);
    },
    setLegParams: (state, action: PayloadAction<LegParams[]>) => {
      state.form.legParams = state.form.legParams.updateValue(action.payload);
      validateLegParams(state);
    },
    setMultilegType: (state, action: PayloadAction<StringSelectItem>) => {
      if (action.payload?.value !== state.form.multilegTypeField.value?.value) {
        state.form.symbolField = state.form.symbolField.updateValue(undefined);
      }
      state.form.multilegTypeField = state.form.multilegTypeField.updateValue(action.payload);
    },
    setDDHEnabled: (state, action: PayloadAction<boolean>) => {
      state.form.isDDHEnabled = state.form.isDDHEnabled.updateValue(action.payload);
      state.form.ddhStrategyField = state.form.ddhStrategyField.setIsRequired(action.payload);
      state.form.ddhSymbolField = state.form.ddhSymbolField.setIsRequired(action.payload);
      const ddhDefaultsByID = state.referenceData.settings.ddhDefaultsByID;
      if (state.form.isDDHEnabled.value && state.form.option && ddhDefaultsByID) {
        const id = getDDHDefaultsID(state.form.option);
        const defaults = ddhDefaultsByID.get(id);
        // Use saved defaults for selected coin and market
        if (
          !state.form.ddhSymbolField.value &&
          defaults?.symbol &&
          state.form.ddhSymbolField.availableItems.some(item => item.Symbol === defaults.symbol)
        ) {
          state.form.ddhSymbolField = state.form.ddhSymbolField.updateValue(
            state.referenceData.securities.securitiesBySymbol.get(defaults.symbol)
          );
        }
        if (
          !state.form.ddhStrategyField.value &&
          defaults?.strategy &&
          state.form.ddhStrategyField.availableItems.some(item => item.Name === defaults.strategy)
        ) {
          state.form.ddhStrategyField = state.form.ddhStrategyField.updateValue(
            state.referenceData.strategies.ddhStrategiesList.find(s => s.Name === defaults.strategy)
          );
        }
      }
    },
    setDDHStrategy: (state, action: PayloadAction<OrderStrategy>) => {
      const strategy = action.payload.Name && state.referenceData.strategies.strategiesByName.get(action.payload.Name);
      if (!strategy) {
        return;
      }

      state.form.ddhStrategyField = state.form.ddhStrategyField.updateValue(strategy);
    },
    setDDHSymbol: (state, action: PayloadAction<string | undefined>) => {
      const symbol = action.payload && state.referenceData.securities.securitiesBySymbol.get(action.payload);
      if (!symbol) {
        return;
      }

      state.form.ddhSymbolField = state.form.ddhSymbolField.updateValue(symbol);
    },
    updateInitiatingLegs: (state, action: PayloadAction<{ value: boolean; legIndex: number }>) => {
      const { value, legIndex } = action.payload;
      const legParams = state.form.legParams.value;
      if (legParams?.[legIndex]) {
        legParams[legIndex].Initiating = value;
        state.form.legParams = state.form.legParams.updateValue(legParams);
        validateLegParams(state);
      }
    },
    updateLegMarkets: (state, action: PayloadAction<{ value: string[]; legIndex: number }>) => {
      const { value, legIndex } = action.payload;
      const legParams = state.form.legParams.value;
      if (legParams?.[legIndex]) {
        legParams[legIndex].Markets = value;
        state.form.legParams = state.form.legParams.updateValue(legParams);
        validateLegParams(state);
      }
    },
    updateUnifiedLiquidityLegMarkets: (state, action: PayloadAction<string[]>) => {
      const legParams = state.form.unifiedLiquidityLegParams.value;
      if (legParams && legParams[0]) {
        legParams[0].Markets = action.payload;
        state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.updateValue(legParams);
        validateUnifiedLiquidityLegParams(state);
      }
    },
  },
  // https://redux-toolkit.js.org/api/createSlice#extrareducers
  // extraReducers allows own state update in response to action generated from other slices
  // in other words, we can listen to actions from another slice (reference data) to update this slice
  // the use case for now is when the top level OMS settings is updated, we can compare the new values (payload)
  // against the current values to determine what has changed and react appropriately
  extraReducers: builder => {
    builder.addCase(setGlobalSymbol, (state, action) => {
      handleSymbolChange(state, action.payload);
    });
    builder.addCase(setOMSSettings, (state, action) => {
      const current = state.referenceData.settings;
      if (state.orderBeingModified || !current) {
        return;
      }

      const enableDerivativeContractDefaultChanged =
        current.enableDerivativeContractDefault !== action.payload.enableDerivativeContractDefault;

      // Contract currency is modelled as empty string
      if (enableDerivativeContractDefaultChanged && state.form.orderCurrencyField.value == null) {
        setInitialOrderCcy(state);
      }

      const defaultPriceProtectionChanged = current.defaultPriceProtection !== action.payload.defaultPriceProtection;
      if (
        defaultPriceProtectionChanged &&
        state.form.parameters?.PriceProtection &&
        !state.form.parameters?.PriceProtection.isTouched
      ) {
        state.form.parameters.PriceProtection = state.form.parameters?.PriceProtection.updateValue(
          action.payload.defaultPriceProtection,
          true
        );
      }

      const showAllInPricesChanged = current.showAllInPrices !== action.payload.showAllInPrices;
      if (showAllInPricesChanged && state.form.symbolField.value && state.form.strategyField.value) {
        const ordType = getOrdType(
          action.payload.showAllInPrices,
          state.form.symbolField.value,
          state.form.strategyField.value
        );
        updatePriceFieldVisibility(state, ordType);
      }
    });
  },
});

export const {
  setReferenceData,
  setAdditionalDependencies,
  setSymbol,
  setSide,
  setQuantity,
  setOrderCurrency,
  setLimitPrice,
  setLimitPriceMode,
  setStrategy,
  setStrategyParams,
  setAllocations,
  setAllocationValueType,
  setOrderMarkets,
  setAvailableOrderMarkets,
  setComments,
  setStagedOrder,
  setGroup,
  modifyOrder,
  resubmitOrder,
  openNewOrderForm,
  primeNewOrderForm,
  resetState,
  touchAll,
  filterProductType,
  updateOption,
  updatePerp,
  updateFuture,
  updateInitiatingLegs,
  updateLegMarkets,
  updateUnifiedLiquidityLegMarkets,
  setIsLoading,
  setMarginCostRequestID,
  setMarginCost,
  setPositions,
  setMarketDataStatistics,
  setLegParams,
  setDDHEnabled,
  setDDHStrategy,
  setDDHSymbol,
  onMount,
  setMultilegType,
  setOrderPreset,
  createOrderPreset,
  updateOrderPreset,
} = orderSlice.actions;

export const selectSecuritiesForProductType = createSelector(
  (state: AppState) => state.order.referenceData.securities.securitiesList,
  (state: AppState) => state.order.productGroup,
  (state: AppState) => state.order.form.multilegTypeField.value?.value as SyntheticProductTypeEnum,
  (securities, productGroup, multilegType) => {
    return securities.filter(s => {
      const group = getGroup(s);

      if (group !== 'Multi') {
        return group === productGroup;
      } else {
        return group === productGroup && s.MultilegDetails?.SyntheticProductType === multilegType;
      }
    });
  }
);
export const selectEnableCounterCurrencySelection = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (security?: Security) => {
    return (
      security?.ProductType !== ProductTypeEnum.Basis &&
      (security?.ProductType === ProductTypeEnum.Spot || canCcyFutureOrPerp(security))
    );
  }
);

export const selectModifyingCard = createSelector(
  (state: AppState) => state.cards.openOrdersData,
  (state: AppState) => state.cards.otherOrdersData,
  (state: AppState) => state.order.orderBeingModified,
  (state: AppState) => state.cards.expandedCardID,
  (ownOrdersData, otherOrdersData, orderBeingModified, expandedCardID) => {
    if (!orderBeingModified) {
      return null;
    }
    const data = ownOrdersData.get(orderBeingModified.OrderID) || otherOrdersData.get(orderBeingModified.OrderID);
    return expandedCardID === orderBeingModified.OrderID && data ? { ...data, showDetails: true } : data;
  }
);

export const selectUseTradeAllocations = createSelector(
  (state: AppState) => state.order.referenceData.settings.useTradeAllocations,
  (state: AppState) => state.order.orderBeingModified,
  (state: AppState) => state.order.dependencies.tradableSubAccounts.length,
  (useTradeAllocationsSetting, orderBeingModified, subaAcountCount) => {
    // if there is no subaccounts in system, then ignore this setting
    const useTradeAllocations = !subaAcountCount ? false : useTradeAllocationsSetting;

    // Use trading settings, unless modifying an order with allocation, if the order is allocation based it must remain that way.
    return orderBeingModified ? orderBeingModified.Allocation != null : useTradeAllocations;
  }
);

export const selectDisplayCounterAmount = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  security => {
    return (
      (isSpot(security) || isFuture(security) || isPerpetualSwap(security) || isCFD(security)) &&
      !isSecurityQuantoFuture(security)
    );
  }
);

export const selectMarginCost = createSelector(
  (state: AppState) => state.order.form.orderMarketsField.value,
  (state: AppState) => state.order.marginCostResponse,
  (marketAccounts, response) => {
    const marketAccount = marketAccounts[0];
    if (!response || !response.length || !marketAccount) {
      return undefined;
    }

    const marginForAccount = response.find(m => m.MarketAccount === marketAccount);

    return marginForAccount;
  }
);

export const selectAvailableBalance = createSelector(
  selectMarginCost,
  (state: AppState) => state.referenceData.marketAccounts.marketAccountsByName,
  (state: AppState, balancesByMarketAccountIDCurrency: Map<number, Map<string, Balance>> | undefined) =>
    balancesByMarketAccountIDCurrency,
  (
    marginForAccount: MarginCostResponse | undefined,
    marketAccountsByName: Map<string, MarketAccount>,
    balancesByMarketAccountIDCurrency: Map<number, Map<string, Balance>> | undefined
  ) => {
    if (marginForAccount && marginForAccount.Status === 'Success') {
      const marketAccount = marketAccountsByName.get(marginForAccount.MarketAccount);
      if (marketAccount && balancesByMarketAccountIDCurrency) {
        const balancesByCurrency = balancesByMarketAccountIDCurrency.get(marketAccount.MarketAccountID);
        if (balancesByCurrency) {
          const balance = balancesByCurrency.get(marginForAccount.InitialMarginCurrency!);
          return balance?.AvailableMargin;
        }
      }
    }
  }
);

export const selectIsFormValid = createSelector(
  (state: AppState) => state.order.form,
  (state: AppState) => state.order.dependencies.tradableSubAccounts.length,
  (form, numTradableSubAccounts) => {
    const hasValidParameters = parametersAreValid(form.parameters);
    const hasValidAllocations = allocationsAreValid(form.allocations, numTradableSubAccounts);
    const hasValidPrincipalFields = principalFieldsAreValid(form);

    return hasValidParameters && hasValidAllocations && hasValidPrincipalFields;
  }
);

export const selectAreParametersTouched = createSelector(
  (state: AppState) => state.order.form.parameters,
  parameters => {
    return Object.values(parameters).some(field => field.isTouched);
  }
);

export const selectAreAllocationsTouched = createSelector(
  (state: AppState) => state.order.form.allocations,
  allocations => {
    return allocations.some(allocation => allocation.subAccountField.isTouched || allocation.valueField.isTouched);
  }
);

export const selectCanSavePreset = createSelector(
  (state: AppState) => state.order.form,
  (state: AppState) => state.order.dependencies.tradableSubAccounts.length,
  selectAreParametersTouched,
  selectAreAllocationsTouched,
  (form, numTradableSubAccounts, areParametersTouched, areAllocationsTouched) => {
    // If a preset has already been selected, we also need to check if the form has been modified
    if (
      form.orderPresetField.value &&
      !areParametersTouched &&
      !form.strategyField.isTouched &&
      !areAllocationsTouched
    ) {
      return {
        valid: false,
        message: 'Unable to save a new preset until the form has been modified.',
      };
    }

    const hasSymbolSelected = form.symbolField.value;
    const hasValidParameters = parametersAreValid(form.parameters);
    const hasValidAllocations = allocationsAreValid(form.allocations, numTradableSubAccounts);

    // When checking the so-called "principal fields" (e.g. Qty, Price, Symbol, ...) we exclude the Price field
    // Note: I expect this list to grow as we add more fields to the form
    const excludedFields = ['priceField', 'legParams', 'orderMarketsField'] satisfies (keyof OrderFormState)[];
    const hasValidPrincipalFields = principalFieldsAreValid(form, excludedFields);

    const isFormValid = hasSymbolSelected && hasValidParameters && hasValidAllocations && hasValidPrincipalFields;

    if (!isFormValid) {
      return {
        valid: false,
        message: 'Unable to save a preset until all required strategy parameters are set.',
      };
    }

    const startTime = form.parameters.StartTime as DateField | undefined;
    const endTime = form.parameters.EndTime as DateField | undefined;

    // Check that start/end times are unset, or if set, that they are durations
    const isStartTimeDateTime =
      !!startTime?.value && startTime?.dateTimeDuration?.type === DateTimeDurationPickerValueType.DateTime;
    const isEndTimeDateTime =
      !!endTime?.value && endTime?.dateTimeDuration?.type === DateTimeDurationPickerValueType.DateTime;
    if (isStartTimeDateTime || isEndTimeDateTime) {
      return {
        valid: false,
        message: 'Unable to save a preset when a specific time is set for the Start or End Time.',
      };
    }

    // Check that sub account allocations are unset, or if set, that they are percentages
    const hasPercentageAllocations = form.allocations.some(a => a.valueField.unit === Unit.Percent);
    if (form.allocations.length && !hasPercentageAllocations) {
      return {
        valid: false,
        message: 'Unable to save a preset when Sub Accounts are allocated specific amounts.',
      };
    }

    return {
      valid: true,
      message: undefined,
    };
  }
);

export const selectShouldCheckReasonability = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.strategyField.value,
  (state: AppState) => state.order.referenceData.settings.showAllInPrices,
  (state: AppState) => state.order.referenceData.settings.alwaysCheckPriceReasonability,
  (state: AppState) => state.order.marketDataStatistics?.MinOrderPrice,
  (state: AppState) => state.order.marketDataStatistics?.MaxOrderPrice,
  (security, strategy, showAllInPrices, alwaysCheckPriceReasonability, minPrice, maxPrice) => {
    const ordType = getOrdType(showAllInPrices, security, strategy);
    // Don't validate price on Market Orders as we are not sending a price
    const isMarketOrder = ordType === OrdTypeEnum.Market;

    // [ch23704] Disable price reasonability checks for some algo orders
    const isStrategyToCheck =
      strategy !== undefined && !DISABLED_REASONABILITY_CHECK_STRATEGIES.has(strategy?.Name as OrderStrategiesEnum);

    // [ch73865] Don't perform price reasonability checks on basis orders or options
    const shouldCheckReasonability =
      !isMarketOrder &&
      shouldDisplayReasonabilityCheckForProduct(security) &&
      (isStrategyToCheck || alwaysCheckPriceReasonability);

    // [UI-2730] - Do not check reasonability when Option && Min/MaxOrderPrice is present
    if (isOption(security) && minPrice && maxPrice) {
      return false;
    }

    return !!shouldCheckReasonability;
  }
);
export const selectPositions = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.orderMarketsField.value,
  (state: AppState) => state.order.positions,
  (state: AppState) => state.order.form.parameters.UnifiedLiquidity,
  (state: AppState) => state.order.referenceData.unifiedLiquidityTokens,
  (state: AppState) => state.order.referenceData.marketAccounts.marketAccountsByName,
  (state: AppState) => state.order.referenceData.securities.securitiesBySymbol,
  (
    security,
    markets,
    positions,
    unifiedLiquidity,
    unifiedLiquidityTokens,
    marketAccountsByName,
    securitiesBySymbol
  ) => {
    if (!security) {
      return undefined;
    }

    // Only get positions for derivatives
    if (
      !(
        [ProductTypeEnum.Option, ProductTypeEnum.Future, ProductTypeEnum.PerpetualSwap].includes(
          security.ProductType
        ) && security.Composition !== InstrumentCompositionEnum.Synthetic
      )
    ) {
      return undefined;
    }

    const marketAccountForActualSymbol = markets.find(ma => {
      const marketAccount = marketAccountsByName.get(ma);
      if (!security.Markets) {
        return false;
      }
      return security.Markets?.some(m => m === marketAccount?.Market) || false;
    });

    if (!marketAccountForActualSymbol) {
      return undefined;
    }

    if (isSecurityQuantoFuture(security)) {
      const quantoPosition = positions.get(security.Symbol + marketAccountForActualSymbol)?.Amount || '0.0';
      return {
        currency: '',
        positions: [
          {
            value: quantoPosition,
            currency: '',
            marketAccount: marketAccountForActualSymbol,
          },
        ],
      };
    }

    // Build up collection of all symbols and market accounts to get positions for
    const allSymbolAndMarketAccounts = [
      {
        symbol: security.Symbol,
        marketAccount: marketAccountForActualSymbol,
      },
    ];

    // If unified liquidity is enabled, display aggregate position for all symbols
    if (unifiedLiquidity?.value === UnifiedLiquidityEnum.Enabled) {
      const unifiedTokens = unifiedLiquidityTokens?.get(security.Symbol)?.Tokens || [];

      unifiedTokens?.forEach(t => {
        const marketAccount = markets.find(m => {
          return marketAccountsByName.get(m)?.Market === t.Market;
        });
        if (!marketAccount) {
          return undefined;
        }
        allSymbolAndMarketAccounts.push({
          symbol: t.Symbol,
          marketAccount: marketAccount,
        });
      });
    }

    const allPositionAmounts = getPositionAmounts(allSymbolAndMarketAccounts, positions, securitiesBySymbol);

    return {
      currency: allPositionAmounts[0]?.currency || '',
      positions: allPositionAmounts,
    };
  }
);

/**
 * Given a set of symbols and market accounts, return the position amounts for each combo.
 * Note: I did not move this out to a utility because it is pretty slice specific / not generally useful.
 */
function getPositionAmounts(
  marketAccountAndSymbols: { marketAccount: string; symbol: string }[],
  positions: Map<string, Position>,
  securitiesBySymbol: Map<string, Security>
): { value: string; currency: string; marketAccount: string }[] {
  const amounts = marketAccountAndSymbols.map(symbolAndMA => {
    const position = positions.get(symbolAndMA.symbol + symbolAndMA.marketAccount);
    return {
      ...getPositionAmount(position?.Amount || '0.0', securitiesBySymbol.get(symbolAndMA.symbol)),
      marketAccount: symbolAndMA.marketAccount,
    };
  });
  return amounts;
}

export const selectSupportMarginCost = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.orderMarketsField.value,
  (state: AppState) => state.order.referenceData.marketAccounts.marketAccountsByName,
  (state: AppState) => state.order.referenceData.markets.marketsByName,
  (state: AppState) => state.order.form.parameters?.UnifiedLiquidity,
  (state: AppState) => state.order.form.priceModeField.value,
  (state: AppState) => state.order.form.priceField,
  (state: AppState) => state.order.form.quantityField,
  (
    security,
    selectedMarketAccounts,
    marketAccountsByName,
    marketsByName,
    unifiedLiquidity,
    priceMode,
    priceField,
    quantityField
  ) => {
    if (!security || priceMode !== PricingMode.Default || !priceField.isVisible || !priceField.hasValue) {
      return false;
    }
    const isSecuritySupported =
      [ProductTypeEnum.Option, ProductTypeEnum.PerpetualSwap, ProductTypeEnum.Future].includes(security.ProductType) &&
      security.Composition !== InstrumentCompositionEnum.Synthetic;

    const selectedMarketName = marketAccountsByName.get(selectedMarketAccounts[0])?.Market || '';
    const isMarketSupported = marketsByName.get(selectedMarketName)?.Flags?.SupportsMarginCost;
    const isUnifiedLiquidityEnabled = unifiedLiquidity?.value === UnifiedLiquidityEnum.Enabled;

    // If user type 0, 0.0 don't perform margin cost check
    const zeroPrice = toBigWithDefault(priceField.value, 0).eq(0);
    const zeroQty = toBigWithDefault(quantityField.value, 0).eq(0);

    return isSecuritySupported && isMarketSupported && !isUnifiedLiquidityEnabled && !zeroPrice && !zeroQty;
  }
);

export const selectSupportReduceOnly = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.strategyField.value,
  (state: AppState) => state.order.form.orderMarketsField.value,
  (state: AppState) => state.order.form.parameters?.UnifiedLiquidity,
  (state: AppState) => state.order.referenceData.marketAccounts.marketAccountsByName,
  (state: AppState) => state.order.referenceData.markets.marketsByName,
  (
    security: Security | undefined,
    strategy: OrderStrategy | undefined,
    selectedMarketAccounts: string[],
    unifiedLiquidity,
    marketAccountsByName,
    marketsByName
  ): boolean => {
    const isUnifiedLiquidityEnabled = unifiedLiquidity?.value === UnifiedLiquidityEnum.Enabled;
    return getReduceOnlySupport(
      security,
      strategy,
      selectedMarketAccounts,
      isUnifiedLiquidityEnabled,
      marketAccountsByName,
      marketsByName
    );
  }
);

export const selectSupportReduceFirst = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.parameters?.UnifiedLiquidity,
  (security: Security | undefined, unifiedLiquidity): boolean => {
    const isUnifiedLiquidityEnabled = unifiedLiquidity?.value === UnifiedLiquidityEnum.Enabled;
    return getReduceFirstSupport(security, isUnifiedLiquidityEnabled);
  }
);

export const selectIsLimitAllInOrder = createSelector(
  (state: AppState) => state.order.orderBeingModified,
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.strategyField.value,
  (state: AppState) => state.order.referenceData.settings.showAllInPrices,
  (orderBeingModified, security, strategy, showAllInPrices) => {
    const ordType = getOrdType(showAllInPrices, security, strategy);
    return orderBeingModified
      ? orderBeingModified.OrdType === OrdTypeEnum.LimitAllIn
      : ordType === OrdTypeEnum.LimitAllIn;
  }
);

export const selectShouldDisplayPriceBpsButtons = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  security => !isOption(security) && !isDelta1Multileg(security) && !isOptionStrategy(security)
);

export const selectSymbolAndMarkets = createSelector(
  (state: AppState) => state.order.form.symbolField.value,
  (state: AppState) => state.order.form.orderMarketsField.value,
  (security, markets) => ({ symbol: security?.Symbol, markets })
);

export const selectMinMaxOrderPrice = createSelector(
  (state: AppState) => state.order.marketDataStatistics?.MinOrderPrice,
  (state: AppState) => state.order.marketDataStatistics?.MaxOrderPrice,
  (minPrice, maxPrice) => ({ minPrice, maxPrice })
);

export const selectUnderlyingPrice = createSelector(
  (state: AppState) => state.order.marketDataStatistics?.UnderlyingPrice,
  underlyingPrice => underlyingPrice
);

function handleUnifiedLiquidity(action: { payload: PrimeOMSParams; type: string }, state: WritableDraft<OrderState>) {
  if (action.payload.parameters?.unifiedLiquidity && state.form.parameters.UnifiedLiquidity) {
    state.form.parameters.UnifiedLiquidity = state.form.parameters.UnifiedLiquidity.updateValue(
      action.payload.parameters?.unifiedLiquidity
    );

    if (
      isMultiLegSecurity(state.form.symbolField.value) &&
      action.payload.parameters?.unifiedLiquidity === UnifiedLiquidityEnum.Enabled
    ) {
      const legParams =
        action.payload.unifiedLiquidityLegParams ??
        getUnifiedLiquidityLegParams(state.form.symbolField.value.Symbol, state.referenceData.unifiedLiquidityTokens);
      state.form.unifiedLiquidityLegParams = state.form.unifiedLiquidityLegParams.updateValue(legParams, true);
      validateUnifiedLiquidityLegParams(state);
    }
  }
}

function parametersAreValid(parameters: Record<string, BaseField<any>>) {
  const parametersHasError = Object.keys(parameters).some(key => parameters[key].hasError);
  return !parametersHasError;
}

function allocationsAreValid(
  allocations: { subAccountField: Field<string>; valueField: NumericField }[],
  numTradableSubAccounts: number
) {
  const isSubAccountRequired = numTradableSubAccounts > 0;
  const allocationsHasError = isSubAccountRequired
    ? allocations.length === 0 ||
      allocations.flatMap(alloc => [alloc.subAccountField, alloc.valueField]).some(field => field.hasError)
    : false;
  return !allocationsHasError;
}

/**
 * Check if all principal fields are valid
 * @param form - order form state
 * @param excludedFields - fields to exclude from validation
 */
function principalFieldsAreValid(form: OrderFormState, excludedFields: (keyof OrderFormState)[] = []) {
  const principalFields: BaseField<FieldData>[] = Object.keys(form)
    .filter(k => !excludedFields.includes(k as keyof OrderFormState))
    .filter(k => form[k] instanceof BaseField)
    .map(k => form[k]);
  const principalHasError = principalFields.some(f => f.hasError);
  return !principalHasError;
}

export const selectInitiatingLegs = createSelector(
  (state: AppState) => state.order.form.legParams.value,
  (legParams: LegParams[] | undefined): boolean[] => {
    if (!legParams) {
      return [];
    }
    return legParams.map(leg => leg.Initiating);
  }
);

export const selectLegMarkets = createSelector(
  (state: AppState) => state.order.form.legParams.value,
  (legParams: LegParams[] | undefined): string[][] => {
    if (!legParams) {
      return [];
    }
    return legParams.map(leg => leg.Markets);
  }
);
