import type { Position } from '@talos/kyoko';
import type { HierarchicalGridData } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { BasePortfolioManagementDataItem } from './BasePortfolioManagementDataItem';
import type { PortfolioRiskDataItem } from './PortfolioRiskDataItem';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from './PortfolioRiskSubscriptionData';

export class PortfolioManagementBlotterDataItem implements BasePortfolioManagementDataItem, HierarchicalGridData {
  hierarchicalRowInfo: string[];
  skipAggregation: boolean;
  rowID: string;

  gridData: PortfolioRiskGridData;
  riskData: PortfolioRiskSubscriptionData;
  position?: Position;

  constructor(data: PortfolioRiskDataItem, position?: Position) {
    const gridData = data.gridData;
    this.hierarchicalRowInfo = gridData.hierarchicalRowInfo;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;
    this.gridData = gridData;
    this.position = position;
    this.riskData = data.riskData;
  }
}
