import type { RowGroupsOpenedState } from '@talos/kyoko';
import { usePortfolioViewStateSelector } from '../../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import { OperationsOverviewBlotter } from './OperationsOverviewBlotter';
import type { OperationsOverviewBlotterColumnSpecification } from './useOperationsOverviewBlotterColumns';

const sharedColumns = [
  'balance',
  'balanceEquivalent',
  'position-qty',
  'Equivalent.Amount',
  'Equivalent.UnrealizedPnL',
  'equityEquivalent',
  'Equivalent.initialMargin',
  'Equivalent.maintenanceMargin',
] satisfies OperationsOverviewBlotterColumnSpecification[];

const defaultRowGroupsOpened: RowGroupsOpenedState = {};

const byMarketColumns = [
  'reconWarning',
  { field: 'market', rowGroupIndex: 0, rowGroup: true, hide: true },
  { field: 'MarketAccount', rowGroupIndex: 1, rowGroup: true, hide: true },
  { field: 'underlying', rowGroupIndex: 2, rowGroup: true, hide: true },
  { field: 'Asset', rowGroupIndex: 3, rowGroup: true, hide: true },
  'weight',
  ...sharedColumns,
] satisfies OperationsOverviewBlotterColumnSpecification[];

const byAssetColumns = [
  'reconWarning',
  { field: 'underlying', rowGroupIndex: 0, rowGroup: true, hide: true },
  { field: 'Asset', rowGroupIndex: 1, rowGroup: true, hide: true },
  { field: 'MarketAccount', rowGroupIndex: 2, rowGroup: true, hide: true },
  ...sharedColumns,
] satisfies OperationsOverviewBlotterColumnSpecification[];

// This component wraps the blotter and specifically handles grabbing the correct blotterID and passing it to the blotter,
// also making sure to re-mount the blotter on blotterID change
export const OperationsOverviewBlotterContainer = () => {
  const { opsOverviewShowBy: showBy } = usePortfolioViewStateSelector();

  const columnsToUse = showBy === 'Asset' ? byAssetColumns : byMarketColumns;
  // flatten our 3 showBys to just two since mktacc and mkt are to be shown the same way in the blotter at least
  const byAssetOrMarket = showBy === 'Asset' ? 'Asset' : 'Market';

  // We keep two blotter IDs, one for each showBy (by market or by asset). These are regarded as different blotters for the sake of persistance
  const blotterID = `OPERATIONS_OVERVIEW_BLOTTER/${byAssetOrMarket}`;
  return (
    <OperationsOverviewBlotter
      key={blotterID}
      blotterID={blotterID}
      defaultColumns={columnsToUse}
      defaultRowGroupsOpened={defaultRowGroupsOpened}
    />
  );
};
