import {
  ACTION,
  type Column,
  type ColumnDef,
  getAgGridColId,
  type Leaves,
  MixpanelEventSource,
  useDefaultColumns,
  useDynamicCallback,
} from '@talos/kyoko';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlag, useRoleAuth, useTransfersDrawer } from '../../../../hooks';
import { useTransfers } from '../../../../providers';
import { useDisplaySettings } from '../../../../providers/DisplaySettingsProvider';
import { useTradePositionColumn } from '../../../../utils/columns/useTradePositionColumn';
import { useTransferButtonColumn } from '../../../../utils/columns/useTransferButtonColumn';
import type { TransferForm } from '../../../Transfers/types';
import type { OperationsOverviewShowBy, OpsPosition } from '../types';
import { MARKET_ACCOUNT_COLID, weightColumn } from './weightColumn';

function getPositionAssetType(p: OpsPosition) {
  return p.AssetType;
}

type OpsPositionLeaves = Leaves<OpsPosition>;
type OpsPositionColumn = Leaves<OpsPosition> | Partial<ColumnDef<OpsPosition>>;

// Create a simple type here to give us an extra layer of type safety around these parts
export type ExtraOperationsOverviewColumns = 'position-qty' | 'buy' | 'sell' | 'deposit' | 'withdraw' | 'weight';

export type OperationsOverviewBlotterColumnSpecification = OpsPositionColumn | ExtraOperationsOverviewColumns;

export const useOperationsOverviewBlotterColumns = (
  defaultColumns: OperationsOverviewBlotterColumnSpecification[],
  showBy: OperationsOverviewShowBy
) => {
  const { homeCurrency } = useDisplaySettings();
  const { showPositionsBlotterWarningColumn } = useFeatureFlag();

  const { getPrimeableTransfer } = useTransfers();
  const { openTransfersDrawer } = useTransfersDrawer();

  const handleOpenTransfersDrawer = useDynamicCallback((primedTransfer: TransferForm) => {
    openTransfersDrawer({ initialTransfer: primedTransfer, source: MixpanelEventSource.TreasuryBlotter });
  });

  const { isAuthorized } = useRoleAuth();
  const authorizedToTrade = isAuthorized(ACTION.SUBMIT_ORDER);
  const authorizedToTransfer = isAuthorized(ACTION.SUBMIT_TRANSFER);

  const showWeightColumn = showBy !== 'Asset';

  const buyColumn = useTradePositionColumn<OpsPosition>({
    assetField: 'Asset',
    marketAccountField: 'MarketAccount',
    constrainSecuritiesToMarket: true,
    action: 'Buy',
    columnId: 'buy' satisfies ExtraOperationsOverviewColumns,
    getAssetType: getPositionAssetType,
    showTitle: true,
  });

  const sellColumn = useTradePositionColumn<OpsPosition>({
    assetField: 'Asset',
    marketAccountField: 'MarketAccount',
    constrainSecuritiesToMarket: true,
    action: 'Sell',
    columnId: 'sell' satisfies ExtraOperationsOverviewColumns,
    getAssetType: getPositionAssetType,
    showTitle: true,
  });

  const withdrawColumn = useTransferButtonColumn<OpsPosition>({
    currencyField: 'Asset',
    marketField: 'market',
    marketAccountField: 'MarketAccount',
    type: 'withdraw',
    getPrimeableTransfer,
    onClick: handleOpenTransfersDrawer,
    columnID: 'withdraw' satisfies ExtraOperationsOverviewColumns,
    tryWithLinkedAccount: true,
  });

  const depositColumn = useTransferButtonColumn<OpsPosition>({
    currencyField: 'Asset',
    marketField: 'market',
    marketAccountField: 'MarketAccount',
    type: 'deposit',
    getPrimeableTransfer,
    onClick: handleOpenTransfersDrawer,
    columnID: 'deposit' satisfies ExtraOperationsOverviewColumns,
    tryWithLinkedAccount: true,
  });

  const columnDefinitions = useMemo(() => {
    const cols: Column[] = compact([
      showPositionsBlotterWarningColumn && {
        id: 'reconWarning' satisfies OperationsOverviewBlotterColumnSpecification,
        type: 'reconWarning',
        field: 'reconWarning',
        aggregate: true,
        sortable: true,
      },
      {
        id: 'Asset',
        field: 'Asset',
        type: 'asset',
        sortable: true,
        title: 'Instrument',
        params: {
          assetTypeField: 'AssetType' satisfies OpsPositionLeaves,
          colorful: true,
        },
        description: 'The specific spot or derivatives asset held in the position.',
      },
      {
        field: 'underlying',
        type: 'currency',
        title: 'Underlying',
        params: {
          colorful: true,
          showDescription: false,
        },
        hide: true,
        description: 'For derivatives contracts, the underlying asset symbol.',
      },
      {
        field: 'market',
        type: 'market',
        hide: true,
        sortable: true,
        description: 'Venue (exchange, OTC brokerage, custodian) where positions are held.',
      },
      {
        field: MARKET_ACCOUNT_COLID,
        type: 'account',
        title: 'Account',
        sortable: true,
        description: 'Specific venue account (a.k.a. market account) where positions are held.',
      },
      {
        field: 'AssetType',
        title: 'Product Type',
        type: 'productTypeField',
        hide: true,
        width: 130,
        enableRowGroup: true,
        description: 'High-level asset class like spot, future or perpetual for the instrument.',
      },
      showWeightColumn && weightColumn,
      {
        field: 'Amount',
        id: 'position-qty' satisfies OperationsOverviewBlotterColumnSpecification,
        type: 'size',
        title: 'Position Qty',
        params: {
          showInTermsOfContracts: true,
          highlightNegative: true,
          currencyField: 'Asset' satisfies OpsPositionLeaves,
          trimTrailingZeroes: true,
        },
        description: 'Quantity of derivative contracts or size of spot position.',
      },
      {
        field: 'Amount',
        type: 'size',
        title: 'Position',
        sortable: true,
        aggregate: true,
        width: 150,
        params: {
          currencyField: 'Asset',
          highlightNegative: true,
          showCurrencyForNullPosition: false,
        },
        description: 'Exposure to spot assets and derivatives contracts, in quantity of assets.',
      },
      {
        type: 'size',
        field: 'Equivalent.Amount',
        title: `Position (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves, highlightNegative: true },
        sortable: true,
        aggregate: true,
        description: 'Exposure to spot assets and derivatives contracts, in home currency.',
      },
      {
        field: 'balance',
        type: 'size',
        title: `Balance`,
        params: {
          highlightNegative: true,
          currencyField: 'Asset' satisfies OpsPositionLeaves,
        },
        hide: true,
        aggregate: true,
        description: 'Spot balances, expressed in quantity of assets held.',
      },
      {
        field: 'balanceEquivalent',
        aggregate: true,
        type: 'size',
        title: `Balance (${homeCurrency})`,
        params: {
          highlightNegative: true,
          currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves,
        },
        hide: true,
        description: 'Spot balances, in home currency.',
      },
      {
        field: 'Equivalent.UnrealizedPnL',
        type: 'size',
        title: `Unrealized PnL (${homeCurrency})`,
        aggregate: true,
        params: {
          currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves,
          highlightNegative: true,
        },
        description: 'Unrealized P&L for derivatives positions, in home currency.',
      },
      {
        type: 'size',
        title: `Equity (${homeCurrency})`,
        field: 'equityEquivalent',
        aggregate: true,
        params: { currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves, highlightNegative: true },
        sortable: true,
        description: 'Spot balances plus unrealized P&L for derivatives positions, in home currency.',
      },
      {
        type: 'size',
        title: `Initial Margin (${homeCurrency})`,
        field: 'Equivalent.initialMargin',
        aggregate: true,
        params: { currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves, highlightNegative: true },
        sortable: true,
        description: 'Exchange published initial margin requirement for derivatives positions, in home currency.',
      },
      {
        type: 'size',
        title: `Maintenance Margin (${homeCurrency})`,
        field: 'Equivalent.maintenanceMargin',
        aggregate: true,
        params: { currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves, highlightNegative: true },
        sortable: true,
        width: 200, // dont wanna cut off the maintenance margin header label, looks bad
        description: 'Exchange-published maintenance margin requirement for derivatives positions, in home currency.',
      },
      {
        field: 'OutstandingBuy',
        type: 'size',
        hide: true,
        params: { currencyField: 'Asset' satisfies OpsPositionLeaves, showCurrencyForNullPosition: false },
        description: 'Quantity of assets pending in open buy orders.',
      },
      {
        field: 'OutstandingSell',
        type: 'size',
        hide: true,
        params: { currencyField: 'Asset' satisfies OpsPositionLeaves, showCurrencyForNullPosition: false },
        description: 'Quantity of assets pending in open sell orders.',
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingBuy',
        title: `Outstanding Buy (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves, highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
        description: 'Value of assets pending in open buy orders, in home currency.',
      },
      {
        type: 'size',
        field: 'Equivalent.OutstandingSell',
        title: `Outstanding Sell (${homeCurrency})`,
        params: { currencyField: 'Equivalent.Currency' satisfies OpsPositionLeaves, highlightNegative: true },
        sortable: true,
        hide: true,
        aggregate: true,
        description: 'Value of assets pending in open sell orders, in home currency.',
      },
      authorizedToTrade && buyColumn,
      authorizedToTrade && sellColumn,
      authorizedToTransfer && withdrawColumn,
      authorizedToTransfer && depositColumn,
    ] satisfies (ColumnDef<OpsPosition> | false)[]);

    const map: Map<string, Column> = new Map(cols.map(c => [getAgGridColId(c), c]));
    return map;
  }, [
    homeCurrency,
    showPositionsBlotterWarningColumn,
    authorizedToTrade,
    buyColumn,
    sellColumn,
    authorizedToTransfer,
    withdrawColumn,
    depositColumn,
    showWeightColumn,
  ]);

  const columns = useDefaultColumns(defaultColumns, columnDefinitions);
  return columns;
};
