import type { HierarchicalGridData } from '../components/PortfolioRiskBlotter/useRollupTreeGridBuilders';
import type { BasePortfolioManagementDataItem } from './BasePortfolioManagementDataItem';
import type { PortfolioRiskGridData } from './PortfolioRiskGridData';
import type { PortfolioRiskSubscriptionData } from './PortfolioRiskSubscriptionData';

export class PortfolioRiskDataItem implements BasePortfolioManagementDataItem, HierarchicalGridData {
  hierarchicalRowInfo: string[];
  skipAggregation: boolean;
  rowID: string;

  riskData: PortfolioRiskSubscriptionData;
  gridData: PortfolioRiskGridData;

  constructor(gridData: PortfolioRiskGridData, riskData: PortfolioRiskSubscriptionData) {
    this.hierarchicalRowInfo = gridData.hierarchicalRowInfo;
    this.skipAggregation = gridData.skipAggregation;
    this.rowID = gridData.rowID;

    this.riskData = riskData;
    this.gridData = gridData;
  }
}
