import {
  AccordionGroup,
  Box,
  ExpandableBottomPanel,
  Flex,
  HStack,
  Tab,
  TabAppearance,
  TabList,
  Tabs,
  TabSize,
  toBigWithDefault,
  useExpandablePanel,
  useMarketAccountsContext,
  useMarketsContext,
  useSyncedRef,
  useTabs,
  VStack,
} from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { BlotterWrapper, XScrollableContainer } from '../styles';
import { OperationsPie } from '../TreasuryManagement/Chart/OperationsPie';
import { OperationsOverviewBlotterContainer } from './blotter';
import { ControlPanel } from './components/ControlPanel';
import {
  OperationsOverviewFiltersProvider,
  useOperationsOverviewFilters,
} from './providers/OperationsOverviewFiltersProvider';
import {
  OperationsOverviewInteractionsProvider,
  useOperationsOverviewInteractions,
} from './providers/OperationsOverviewInteractionsProvider';

import styled from 'styled-components';
import { usePortfolioViewStateSelector } from '../PortfolioManagement/stateManagement/portfolioViewLayoutSlice.hooks';
import {
  OperationsOverviewPositionsProvider,
  useOperationsOverviewPositions,
} from './providers/OperationsOverviewPositionsProvider';
import type { OpsPosition } from './types';

const TABS = [
  {
    label: 'Balances and Positions',
    render: () => <OperationsOverviewBlotterContainer />,
  },
];

const DEFAULT_BLOTTER_HEIGHT = 450;
const OPS_OVERVIEW_BLOTTER_HEIGHT = 'OpsOverviewBlotterHeight';

const OperationsOverviewInner = () => {
  const { initialFilterClauses } = useOperationsOverviewFilters();

  const [isBlotterMaximized, setIsBlotterMaximized] = useState(false);
  const [isBlotterMinimized, setIsBlotterMinimized] = useState(false);

  const handleMaximizeBlotter = useCallback((shouldExpand: boolean) => {
    setIsBlotterMinimized(false);
    setIsBlotterMaximized(curr => shouldExpand ?? !curr);
  }, []);

  const handleMinimizeBlotter = useCallback((shouldMinimize: boolean) => {
    setIsBlotterMaximized(false);
    setIsBlotterMinimized(curr => shouldMinimize ?? !curr);
  }, []);

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: TABS,
  });

  const { containerRef, ...panelProps } = useExpandablePanel<HTMLDivElement>({
    initialHeight: parseInt(localStorage.getItem(OPS_OVERVIEW_BLOTTER_HEIGHT) ?? '0') || DEFAULT_BLOTTER_HEIGHT,
    isExpanded: isBlotterMaximized,
    isMinimized: isBlotterMinimized,
    onToggleExpanded: handleMaximizeBlotter,
    onToggleMinimize: handleMinimizeBlotter,
    onAdjustedHeight(newHeight) {
      localStorage.setItem(OPS_OVERVIEW_BLOTTER_HEIGHT, newHeight.toString());
    },
  });

  const { opsOverviewShowBy: showBy } = usePortfolioViewStateSelector();

  const { positionsData } = useOperationsOverviewPositions();
  const getBalanceEquivalentAmount = useCallback((position: OpsPosition) => {
    return toBigWithDefault(position.balanceEquivalent, 0);
  }, []);
  const getPositionEquivalentEquity = useCallback((position: OpsPosition) => {
    return toBigWithDefault(position.equityEquivalent, 0);
  }, []);

  const getPositionGroupBy = useCallback(
    (position: OpsPosition) => {
      if (showBy === 'Asset') {
        return position.underlying;
      }

      if (showBy === 'MarketAccount') {
        return position.MarketAccount;
      }

      return position.market;
    },
    [showBy]
  );

  const { marketsByName } = useMarketsContext();
  const marketsByNameRef = useSyncedRef(marketsByName);

  const { marketAccountsByName } = useMarketAccountsContext();
  const marketAccountsByNameRef = useSyncedRef(marketAccountsByName);

  const getPositionLabel = useCallback(
    (position: OpsPosition) => {
      if (showBy === 'Asset') {
        return position.underlying;
      }
      if (showBy === 'Market') {
        const mkt = position.market ? marketsByNameRef.current.get(position.market) : undefined;
        return mkt?.DisplayName ?? position.market ?? 'Unknown';
      }

      // market account
      const mktAcc = marketAccountsByNameRef.current.get(position.MarketAccount);
      return mktAcc?.DisplayName ?? position.MarketAccount;
    },
    [showBy, marketsByNameRef, marketAccountsByNameRef]
  );

  const { goToGroupRow } = useOperationsOverviewInteractions();

  return (
    <Flex h="100%" w="100%" flexDirection="column" overflow="hidden">
      <AccordionGroup>
        <ControlPanel initialFilterClauses={initialFilterClauses} />
      </AccordionGroup>

      <VStack w="100%" h="100%" overflow="hidden" position="relative" ref={containerRef}>
        <XScrollableContainer w="100%" background="colors.gray.main" justifyContent="center">
          <HStack minHeight="300px" w="100%" h="100%" minWidth="800px" maxWidth="1400px" alignItems="center" mx="auto">
            <OperationsPieWrapper data-testid="balances-pie">
              <OperationsPie
                entities={positionsData}
                showBy={showBy === 'Asset' ? 'asset' : 'market'}
                asOf={null}
                getValue={getBalanceEquivalentAmount}
                getGroupBy={getPositionGroupBy}
                getLabel={getPositionLabel}
                onSliceClick={goToGroupRow}
                centerLabel="Total Balances"
                showing="balances"
              />
            </OperationsPieWrapper>
            <OperationsPieWrapper data-testid="equity-pie">
              <OperationsPie
                entities={positionsData}
                showBy={showBy === 'Asset' ? 'asset' : 'market'}
                asOf={null}
                getValue={getPositionEquivalentEquity}
                getGroupBy={getPositionGroupBy}
                getLabel={getPositionLabel}
                onSliceClick={goToGroupRow}
                centerLabel="Total Equity"
                showing="equities"
              />
            </OperationsPieWrapper>
          </HStack>
        </XScrollableContainer>
        <BlotterWrapper>
          <ExpandableBottomPanel
            {...panelProps}
            showControls
            header={
              <Tabs {...tabs} appearance={TabAppearance.Filled} size={TabSize.Small}>
                <TabList>
                  {TABS.map((tab, i) => (
                    <Tab key={i} {...tab} />
                  ))}
                </TabList>
              </Tabs>
            }
          >
            {TABS.map(
              (tab, i) =>
                tabs.selectedIndex === i && <ErrorBoundary key={`component-${i}`}>{tab.render()}</ErrorBoundary>
            )}
          </ExpandableBottomPanel>
        </BlotterWrapper>
      </VStack>
    </Flex>
  );
};

// simple wrapper to just have consistent layout between the two pie chart sizings
const OperationsPieWrapper = styled(Box)`
  flex: 1 1 50%;
  height: 100%;
  min-width: 300px;
  max-height: 500px;
`;

export const OperationsOverview = () => {
  return (
    <OperationsOverviewFiltersProvider>
      <OperationsOverviewInteractionsProvider>
        <OperationsOverviewPositionsProvider>
          <OperationsOverviewInner />
        </OperationsOverviewPositionsProvider>
      </OperationsOverviewInteractionsProvider>
    </OperationsOverviewFiltersProvider>
  );
};
